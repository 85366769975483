<template>
  <div class="row">
    <div class="col">
      <ridehailing-booking-card :booking="item" />
      <ridehailing-bid-card :bid="item.bid" :bid-status="item.status" @cancel-booking="cancelBooking()" />
    </div>
    <div class="col">
      <company-charges v-if="showChargesBreakdown" :data="item.charge" />
      <stripe-card v-if="item.stripe_payment_intents" :payment_intents="item.stripe_payment_intents" />
      <div id="new-map"></div>
    </div>
    <div class="col">
      <user-records :user="item.user" />
      <company-card :company="item.company" />
    </div>
  </div>
</template>

<script>
import mixin from '../../mixins/mixin';
import CompanyCharges from '../CompanyCharges.vue';
import UserRecords from './cards/UserRecords.vue';
import CompanyCard from './cards/CompanyCard.vue';
import RidehailingBidCard from './cards/RidehailingBidCard.vue';
import RidehailingBookingCard from './cards/RidehailingBookingCard.vue';
import StripeCard from './cards/StripeCard.vue';

const L = window.L;

export default {
  components: { RidehailingBidCard, RidehailingBookingCard, CompanyCharges, UserRecords, CompanyCard, StripeCard },
  name: "RidehailingPanel",
  props: ['item'],
  mixins: [mixin],
  methods: {
    cancelBooking() {
      this.$emit("cancel-booking");
    },
  },
  mounted() {
    this.map = L.map("new-map");
    
    var hailIcon = L.icon({
      iconUrl: '../../../dist/img/hail.png',

      iconSize:     [40, 50],
      iconAnchor:   [18, 50],
      tooltipAnchor:  [0, -50]
    });

    var taxiIcon = L.icon({
      iconUrl: '../../../dist/img/taxi-solid.png',

      iconSize:     [40, 40],
      iconAnchor:   [20, 35],
      tooltipAnchor:  [0, -32]
    });

    L.tileLayer(
      "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}",
      {
        tileSize: 512,
        zoomOffset: -1,
        id: "mapbox/streets-v11",
        accessToken:
          "pk.eyJ1IjoidmljdG9ybGYiLCJhIjoiY2pvOGUxZHB0MDk0NDN0bjFwazJtZWJvayJ9.fCoUbelPKU2m6t88oL1M7w",
      }
    ).addTo(this.map);

    var markers = [];

    // Origin
    if (this.item.bid.actualOrigin) {
      const position = [this.item.bid.actualOrigin.position.latitude,
                        this.item.bid.actualOrigin.position.longitude];
      markers.push(position);
      let marker = L.marker(position, {icon: hailIcon}).addTo(this.map);
      marker.bindTooltip("Pick up", {permanent: true, direction: "top"}).openTooltip();
    }

    // Destination
    if (this.item.bid.actualDestination) {
      const position = [this.item.bid.actualDestination.position.latitude,
                        this.item.bid.actualDestination.position.longitude];
      markers.push(position);
      let marker = L.marker(position).addTo(this.map);
      marker.bindTooltip("Drop off", {permanent: true, direction: "auto"}).openTooltip();
    }

    // Current position
    if (this.item?.vehicle && this.item?.vehicle?.currentPosition?.latitude && this.item?.vehicle?.currentPosition?.longitude &&
    this.item?.vehicle?.currentPosition.latitude != this.item.bid.actualDestination.position.latitude) {
      const position = [this.item.vehicle.currentPosition.latitude,
                        this.item.vehicle.currentPosition.longitude];
      markers.push(position);
      L.marker(position, {icon: taxiIcon}).addTo(this.map);
    }

    if (markers) {
      this.map.fitBounds(markers, {padding: [70, 70]});
    }
  },
  beforeDestroy() {
    if (this.map) {
      this.map.remove();
    }
  }
}
</script>

<style scoped>
#new-map {
  width: 100%;
  height: 100%;
  max-height: 650px;
}
</style>