<template>
    <div class="card border-warning">
        <div class="card-header bg-warning">
            Mobility Service Provider
            <i class="fa-solid fa-building"></i>
        </div>
        <table class="table">
          <tbody>
            <tr>
              <th>ID</th>
              <td>
                <object-name :name="company.name" />
              </td>
            </tr>
            <tr v-if="company.displayName">
              <th>Name</th>
              <td>{{company.displayName}}</td>
            </tr>
            <tr v-if="company.websiteUri">
              <th>Website</th>
              <td>
                <a :href="company.websiteUri" target="blank">
                  {{company.websiteUri}}
                </a>
              </td>
            </tr>
            <tr v-if="company.emailAddress">
              <th>Email</th>
              <td>
                {{company.emailAddress}}
              </td>
            </tr>
            <tr v-if="!showChargesBreakdown">
              <th>Logos</th>
              <td>
                <img :src="company.visualAssets?.squareLogo?.urlSvg" height="30" class="mr-5">
                <img :src="company.visualAssets?.fullWidthLogo?.urlSvg" height="30" class="">
              </td>
            </tr>
            <tr v-if="showChargesBreakdown && company.visualAssets?.squareLogo">
              <th>Square logo</th>
              <td>
                <div class="row">
                  <a v-for="url, key in company.visualAssets.squareLogo" :key="url" :href="url" target="blank" class="col-4 text-center">
                    <figure class="figure">
                      <img :src="url" height="30" class="m-1 mb-1">
                      <figcaption class="figure-caption">
                        {{ formatVisualAssetCaption(key) }}
                      </figcaption>
                    </figure>
                  </a>
                </div>
              </td>
            </tr>
            <tr v-if="showChargesBreakdown && company.visualAssets?.fullWidthLogo">
              <th>Full width logo</th>
              <td>
                <div class="row">
                  <a v-for="url, key in company.visualAssets.fullWidthLogo" :key="url" :href="url" target="blank" class="col-4 text-center">
                    <figure class="figure">
                      <img :src="url" height="30" class="m-1 mb-1">
                      <figcaption class="figure-caption">
                        {{ formatVisualAssetCaption(key) }}
                      </figcaption>
                    </figure>
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
    </div>
</template>

<script>
  import mixin from '../../../mixins/mixin';
  import objectName from './ObjectName.vue';
  export default {
    components: { objectName },
    name: "companyCard",
    props: ['company'],
    mixins: [mixin],
    data() {
      return {
      };
    },
    methods: {
      formatVisualAssetCaption(key) {
        return key.replace("url", "").replace("Svg", "SVG");
      }
    }
  }
</script>

<style>
  table.table {
    margin-bottom: 0
  }
  .card {
    border-width: 1px;
  }
</style>