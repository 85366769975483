import moment from "moment";
import config from '../config';

const iomobMixin = {
    data() {
        return {
            searchTimeout: 0,
            isLoading: false,
            isCancelLoading: false,
            cancellingItem: null,
            mapBoxToken: process.env.VUE_APP_MAPBOX_TOKEN,
            showModal: false,
            showModalPanel: false,
            currentPath: '',
            statusClasses: {
                ACTIVE: "primary",
                INITIATING: "primary",
                UNLOCKED: "primary",
                COMPLETED: "success",
                BOOKED: "primary",
                DELETED: "danger",
                CANCELLED: "danger",
                CANCELED_BY_DRIVER: "danger",
                CANCELED_BY_PROVIDER: "danger",
                CONFIRMED: "primary",
                CREATED: "secondary",
                DRAFTED: "light",
                FINISHED: "success",
                CANCELED_BY_PASSENGER: "danger",
                ALLOCATING_DRIVER: "primary",
                TRAVELING_TO_DESTINATION: "primary",
                AWAITING_FINAL_PRICE: "primary",
                MOVING_TOWARDS_PICKUP_LOCATION: "primary",
                AWAITING_SERVICE_START: "primary",
                AWAITING_PASSENGER: "primary",
                RESERVED: "primary",
                IN_USE: "primary",
                BOOKING_FAILED: "danger",
            },
            status: {
                DELETED: "Cancelled",
                CREATED: "Created",
                ACTIVE: "Active",
                UNLOCKED: "Active",
                COMPLETED: "Completed",
                FINISHED: "Finished",
                BOOKED: "Booked",
                CANCELLED: "Cancelled",
                CONFIRMED: "Confirmed",
                DRAFTED: "Drafted",
                INITIATING: "Booked",
                CANCELED_BY_PASSENGER: "Cancelled by passenger",
                CANCELED_BY_PROVIDER: "Cancelled by provider",
                CANCELED_BY_DRIVER: "Cancelled by driver",
                ALLOCATING_DRIVER: "Allocating driver",
                TRAVELING_TO_DESTINATION: "Travelling to destination",
                AWAITING_FINAL_PRICE: "Awaiting final price",
                MOVING_TOWARDS_PICKUP_LOCATION: "Moving towards pickup location",
                AWAITING_SERVICE_START: "Awaiting service start",
                AWAITING_PASSENGER: "Awaiting passenger",
                RESERVED: "Reserved",
                IN_USE: "In use",
                BOOKING_FAILED: "Booking failed",
            },
            canceledStatuses : [
                "CANCELLED",
                "CANCELED_BY_PASSENGER",
                "CANCELED_BY_PROVIDER",
                "CANCELED_BY_DRIVER",
                "DELETED",
            ],
            dateRange: {
                start: moment().toDate(),
                end: moment().toDate(),
                type: 'book'
            },
            currencies: {
                EUR: "€",
                USD: "$",
                GBP: "£",
            },
        }
    },
    methods: {
        getColumnWidth(field) {
            return `${this.tableCols.find(item => item.field == field).width}%`;
        },
        closeModal() {
            this.showModal = false;
            this.$router.push({ path: this.$route.path });
        },
        manageCustomerColumn() {
            if(this.hasCustomerColumn) {
                let tot = 0;
                this.tableCols = this.tableCols.map(t => {
                  t.width -= (10 / this.tableCols.length);
                  tot += t.width;
                  return t;
                });
                this.tableCols.splice(1, 0, 
                  { text: "Client", 
                    field: "client", sortable: true, width: 10
                  });
            }
        },
        arrayToCsv(data){
            return data.map(row =>
              row
              .map(String)  // convert every value to String
              .map(v => v.replaceAll('"', '""'))  // escape double colons
              .map(v => `"${v}"`)  // quote it
              .join(',')  // comma-separated
            ).join('\r\n');  // rows starting on new lines
        },
        notValidatedClass(data) {
            return data?.validation?.state == 'NOT_VALIDATED' ? 'not-validated' : null
        },
        getStatusLabel(status) {
            if (this.status[status]) {
              return this.status[status];
            }
            return status;
        },
        getStatusClass(status) {
            let c = "badge light badge-";
            if (this.statusClasses[status]) {
              return c + this.statusClasses[status];
            }
            return c;
        },
        isStatusCanceled(status) {
            return this.canceledStatuses.includes(status);
        },
        customizeDate(date) {
            const dateFormat = config.customers[localStorage.getItem('iomob-domain')].dateFormat;
            return moment(date).format(dateFormat);
        },
        differenceBetweenDate(start, end, positiveSign) {
            let result = positiveSign ? "+" : "";
            if (start > end) {
                [start, end] = [end, start];
                result = "– ";
            }

            const startDate = moment(start).startOf("minute");
            const endDate = moment(end).startOf("minute");
            const difference = endDate.diff(startDate);
            const years = moment.duration(difference).years();
            const months = moment.duration(difference).months();
            const days = moment.duration(difference).days();
            const hours = moment.duration(difference).hours();
            const minutes = moment.duration(difference).minutes();
            
            if (years == 1) {
                result += years + " year ";
            }
            if (years > 1) {
                result += years + " years ";
            }
            if (months == 1) {
                result += months + " month ";
            }
            if (months > 1) {
                result += months + " months ";
            }
            if (days == 1) {
              result += days + " day ";
            }
            if (days > 1) {
              result += days + " days ";
            }
            if (hours > 0) {
              result += hours + " h ";
            }
            if (minutes >= 1) {
              result += minutes + " min";
            }
            if (result === "") {
              result += "0 min";
            }
            return result;
        },
        stringInObject(search, object) {
            for (const k in object) {
                switch (typeof (object[k])) {
                    case 'number':
                        if (!!object[k].toString().match(new RegExp(search))) {
                            /**
                             * for debug purposes: it shows the matching key and value
                             */
                            if(config.debug) { console.log("MATCH", k, object[k]); }
                            return true;
                        }
                        break;
                    case 'string':
                        /**
                         * replacing _ with ' '  it's the fastest fix for searching
                         * statuses. Replacing status code with status name could take
                         * a lot of effort and must be done everywhere. If this solution
                         * works well it's not worth it.
                         */
                        if (object[k].replace(/_/g, ' ').match(new RegExp(search, "gi"))) {
                            /**
                             * for debug purposes: it shows the matching key and value
                             */
                            if(config.debug) {  console.log("MATCH", k, object[k]); }
                            return true;
                        }
                        break;
                    case 'object':
                        if (this.stringInObject(search, object[k])) {
                            return true;
                        }
                }
            }
            return false;
        },
        buildPrice(data, field) {            
            if(! (data && data[field])) {
                return null;
            }

            return this.formatPrice(data[field]);
        },        
        getCurrencyLabel(currency) {
            if (this.currencies[currency.toUpperCase()]) {
              return this.currencies[currency.toUpperCase()];
            }
            return currency;
        },
        price(data, field="price") {
            console.warn("Price field is deprecated");
            return this.buildPrice(data, field);
        },
        formatStripePrice(amount, currency) {
            if (currency == "usd") {
                return this.getCurrencyrLabel(currency) + " " + (amount/100).toFixed(2);
            }
            return (amount/100).toFixed(2) + " " + this.getCurrencyLabel(currency);
        },
        formatPrice(amount) {
            if (!amount) {
                return "-";
            }
            let price = 0.0;
            if ("units" in amount) {
                price += parseInt(amount.units);
            }
            if ("nanos" in amount) {
                price += parseInt(amount.nanos) / 1e9;
            }
            price = price.toFixed(2);

            let currency = this.getCurrencyLabel(amount.currencyCode);
            if (amount.currencyCode == "USD" || amount.currencyCode == "GBP") {
                return currency + " " + price;
            }
            return price + " " + currency;
        },
        vehicleName(type) {
            if (type === "POWERED_STANDING_SCOOTER") return "Scooter";
            if (type === "POWERED_SEATED_SCOOTER") return "Motorcycle";
            if (type === "POWERED_BICYCLE") return "Electric bicycle";
            let str = type.replaceAll("_", " ");
            return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
        },
        vehicleIcon(type) {
            if (type === "POWERED_STANDING_SCOOTER") return "fac fa-scooter";
            if (type === "POWERED_SEATED_SCOOTER") return "fa-solid fa-motorcycle";
            if (type === "POWERED_BICYCLE") return "fa-solid fa-bicycle";
            if (type === "BICYCLE") return "fa-solid fa-bicycle";
            if (type === "CAR") return "fa-solid fa-car";
            alert(type);
            return "";
        },  
        getCustomerName(item) {
            const name = item?.name?.split('/')[1];
            if (name === "dev-sdk") { return 'Iomob'; }
            else if (name === "lner") { return 'LNER'; }
            else if (name === "brightbike") { return 'BrightBike'; }
            else if (name === "ccta") { return 'CCTA'; }
            else if (name === "amtrak") { return 'Amtrak'; }
            else if (name === "iomob") { return 'Iomob'; }
            return name;
        },
        sumTwoCharges(c1, c2) {
            if (c1.currencyCode != c2.currencyCode) {
                console.log("Error summing two charges with different currencies", c1, c2);
            }
            let units = (parseInt(c1.units) || 0) + (parseInt(c2.units) || 0);
            let nanos = (c1.nanos || 0) + (c2.nanos || 0);
            
            return {
                units: parseInt(units + Math.floor(nanos/1e9)),
                nanos: nanos % 1e9,
                currencyCode: c1.currencyCode
            }
        },
        sumCharges(charges) {
            let sumCharge = {
                units: 0,
                nanos: 0,
                currencyCode: charges[0].items[0].amount.currencyCode,
            };
            for (let companyCharges of charges) {
                for (let charge of companyCharges.items) {
                    sumCharge = this.sumTwoCharges(charge.amount, sumCharge);
                    if (charge.taxes) {
                        for (let tax of charge.taxes) {
                            sumCharge = this.sumTwoCharges(tax.amount, sumCharge);
                        }
                    }
                }
            }
            return sumCharge;
        },
        languageName(code) {
            if (code === "en") {
                return "English"
            }
            else if (code === "es") {
                return "Spanish"
            }
            return code;
        },
        sortColumn(x, y, col, rowX, rowY) {
            if (col.field === "fullName") {
                if (!rowX.user?.names?.names) {
                    return -1;
                }
                return rowX.user?.names?.names < rowY.user?.names?.names ? -1 : 1;
            }
            if (col.field === "email") {
                if (!rowX.user?.email) {
                    return -1;
                }
                return rowX.user?.email < rowY.user?.email ? -1 : 1;
            }
            if (col.field === "phoneNumber") {
                if (!rowX.user?.phoneNumber) {
                    return -1;
                }
                return rowX.user?.phoneNumber < rowY.user?.phoneNumber ? -1 : 1;
            }
            if (col.field === "company") {
                if (!rowX.company?.displayName) {
                    return -1;
                }
                return rowX.company?.displayName < rowY.company?.displayName ? -1 : 1;
            }
            if (col.field === "status") {
                return rowX.status < rowY.status ? -1 : 1;
            }
            if (col.field === "price") {
                if (!rowX.price && !rowX.cost) {
                    return -1;
                }
                if (!rowY.price && !rowY.cost) {
                    return 1;
                }
                const fieldX = rowX.price ? 'price' : 'cost';
                const fieldY = rowY.price ? 'price' : 'cost';
                return this.buildPrice(rowX, fieldX) < this.buildPrice(rowY, fieldY) ? -1 : 1;
            }
            if(col.field === "expectedPickupTime") {
                return moment(rowX.expectedPickupTime).isBefore(moment(rowY.expectedPickupTime)) ? -1 : 1;
            }
            if(col.field === "startTime") {
                return moment(rowX.startTime).isBefore(moment(rowY.startTime)) ? -1 : 1;
            }
            if(col.field === "createTime") {
                return moment(rowX.createTime).isBefore(moment(rowY.createTime)) ? -1 : 1;
            }
            if(col.field === "durationRideHailing") {
                rowX.durationRidehailing = null;
                rowY.durationRidehailing = null
                const data = {
                    rowX, rowY
                }

                Object.keys(data).forEach(label => {
                    if(data[label].status == "ACTIVE") {                        
                        if(data[label].expectedPickupTime && data[label].expectedArrivalTime) {
                            data[label].durationRidehailing = 
                                moment.duration(
                                        moment(data[label].expectedArrivalTime)
                                        .diff(moment(data[label].expectedPickupTime))
                                ).minutes();
                        }
                    }
                    if(data[label].status == "COMPLETED") {
                        if(data[label].actualPickupTime && data[label].actualArrivalTime) {
                            data[label].durationRidehailing = 
                                moment.duration(
                                        moment(data[label].actualArrivalTime)
                                        .diff(moment(data[label].actualPickupTime))
                                );
                        }
                    }
                });
                if(rowX.durationRidehailing == null) {
                    return -1;
                }
                return data.rowX.durationRidehailing < data.rowY.durationRidehailing ? -1 : 1;
            }
            if(col.field === "duration") {
                rowX.duration = null;
                rowY.duration = null
                const data = {
                    rowX, rowY
                }

                Object.keys(data).forEach(label => {
                        if(data[label].startTime && data[label].endTime) {
                            data[label].duration = 
                                moment.duration(
                                        moment(data[label].endTime)
                                        .diff(moment(data[label].startTime))
                                );
                        }
                });
                if(rowX.duration == null) {
                    return -1;
                }
                return data.rowX.duration < data.rowY.duration ? -1 : 1;
            }
            if(col.field === "vehicle") {
                if(rowX.vehicles.length == 0) {
                    return -1;
                }
                if(rowY.vehicles.length == 0) {
                    return 1;
                }
                return this.vehicleName(rowX.vehicles[0].info.type) < this.vehicleName(rowY.vehicles[0].info.type) ? -1 : 1;
            }

            return rowX < rowY ? -1 : 1;
        }
    },
    computed: {
        hasCustomerColumn() {
            return this.$store.getters.hasCustomerColumn;
        },
        showChargesBreakdown() {
            return this.$store.getters.showChargesBreakdown;
        }
    },    
}

export default iomobMixin;