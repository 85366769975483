<template>
  <div class="object-name" @click="copy" @mouseenter="enter">
    <span v-if="!blank" class="text-truncate name">
      {{ name }}
    </span>
    <i class="fa-regular fa-copy ml-1" style="font-size: 1.25em; position: relative; top: -6px" />
    
    <div class="copy-tooltip-text">
      <span v-if="copied">Copied</span>
      <span v-else>Copy</span>
    </div>
  </div>
</template>

<script>
  export default {
    name: "objectName",
    props: ['name', 'blank'],
    data() {
      return {
        copied: false,
      };
    },
    methods: {
      copy() {
        navigator.clipboard.writeText(this.name);
        this.copied = true;
      },
      enter() {
        this.copied = false;
      }
    }
  }
</script>

<style>
.text-truncate.name {
  max-width: 200px;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.object-name {
  font-family: monospace;
  font-size: 0.8em;
  cursor: pointer;

  position: relative;
  display: block;
}

.copy-tooltip-text {
  visibility: hidden;
  width: 80px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
 
  position: absolute;
  z-index: 1000;
}

.object-name:hover > .copy-tooltip-text {
  visibility: visible;
  width: 80px;
  bottom: 25px;
  left: 30px;
  margin-left: -40px;
}
</style>