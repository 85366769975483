<template>
  <div class="row">
    <div class="col-4">
      <parking-booking-card :booking="item" @cancel-booking="cancelBooking()" />
      <parking-card :parking="item.parking" />
    </div>
    <div class="col-4">
      <company-charges v-if="showChargesBreakdown" :data="item.charge" />
      <stripe-card v-if="item.stripe_payment_intents" :payment_intents="item.stripe_payment_intents" />
      <div id="map"></div>
    </div>
    <div class="col-4">
      <user-records :user="item.user" />
      <company-card :company="item.parking.company" />
    </div>
  </div>
</template>

<script>
import mixin from '../../mixins/mixin';
import CompanyCharges from '../CompanyCharges.vue';
import ParkingBookingCard from './cards/ParkingBookingCard.vue';
import ParkingCard from './cards/ParkingCard.vue';
import UserRecords from './cards/UserRecords.vue';
import CompanyCard from './cards/CompanyCard.vue';
import StripeCard from './cards/StripeCard.vue';

const L = window.L;

export default {
 components: { ParkingCard, ParkingBookingCard, CompanyCharges, UserRecords, CompanyCard, StripeCard },
  name: "ParkingPanel",
  props: ['item'],
  mixins: [mixin],
  methods: {
    cancelBooking() {
      this.$emit("cancel-booking");
    },
    parkingMultipolygon() {
      return this.item.parking.multipolygon?.polygons.map(
        polygon => polygon.vertices.map(point => [point.latitude, point.longitude]));
    }
  },
  mounted() {
    this.map = L.map("map");
    
    L.tileLayer(
      "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}",
      {
        tileSize: 512,
        zoomOffset: -1,
        id: "mapbox/streets-v11",
        accessToken:
          "pk.eyJ1IjoidmljdG9ybGYiLCJhIjoiY2pvOGUxZHB0MDk0NDN0bjFwazJtZWJvayJ9.fCoUbelPKU2m6t88oL1M7w",
      }
    ).addTo(this.map);


    // Parking position
    let position = [this.item.parking.position.latitude, this.item.parking.position.longitude];
    L.marker(position).addTo(this.map);

    // List of points to later fit bounds
    let points = [position];

    // Parking polygon
    let multipolygon = this.parkingMultipolygon();
    if (multipolygon) {
      L.polygon(multipolygon, {color: "#ff7800", weight: 1}).addTo(this.map);
      points.concat.apply([], multipolygon);
    }

    // Fit map bounds
    this.map.fitBounds(points, {padding: [70, 70]});
  },
  beforeDestroy() {
    if (this.map) {
      this.map.remove();
    }
  }
}
</script>

<style scoped>
#map {
  width: 100%;
  height: 100%;
  max-height: 650px;
}
</style>