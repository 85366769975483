<template>
  <div>
    <loading
      v-model="isLoading"
      :active="isLoading"
      :is-full-page="false"
      color="#fff"
      background-color="#000"
    />
    <div class="content">
      <div class="container-fluid pt-2">
        <div class="card">
          <div class="card-header bg-info align-middle ">
            <h1 class="card-title">Ridehailing</h1>
            <div class="card-tools">
              <div class="d-flex justify-content-end align-items-center">
                <date-range
                  @changed="filterByDateRange"
                  :start-date="dateRange.start"
                  :end-date="dateRange.end"
                  :type="dateRange.type"
                />
                <i></i>
                <input
                  type="search"
                  @keyup="applySearch"
                  class="form-control"
                  v-model="searchText"
                  placeholder="Search..."
                />
                <i></i>
                <download-json :data="rowsFiltered" filename="ridehailings.json" />
                <download-csv @click="saveCSV()" />
              </div>
            </div>
          </div>
          <div class="card-body">
            <fast-table :items="rowsFiltered" :columns="tableCols" v-slot="props" @sort="onSort" :defaultSortField="defaultSortField" styleClass="ridehailing">
              <div class="dynamic-item" @click="onRowClick(props.item)">
                <div class="td" :style="`width: ${getColumnWidth('createTime')}`">
                  {{ customizeDate(props.item.createTime) }}
                </div>
                <div class="td" v-if="hasCustomerColumn" :style="`width: ${getColumnWidth('client')}`">
                  {{ getCustomerName(props.item) }}
                </div>
                <div class="td text-truncate" :style="`width: ${getColumnWidth('fullName')}`">
                  <span class="mr-1"
                    :class="notValidatedClass(props.item.user?.names)"
                  >{{ props.item.user?.names?.names }}</span>
                  <span :class="notValidatedClass(props.item.user?.surnames)">{{
                    props.item.user?.surnames?.surnames
                  }}</span>
                </div>
                <div class="td text-truncate" :style="`width: ${getColumnWidth('email')}`">
                  <span :class="notValidatedClass(props.item.user.email)">{{
                    props.item.user.email?.email
                  }}</span>
                </div>
                <div class="td" :style="`width: ${getColumnWidth('company')}`">
                  <img
                    v-if="props.item?.company?.visualAssets?.squareLogo?.urlSvg"
                    :src="props.item.company.visualAssets.squareLogo.urlSvg"
                  />
                  &nbsp;
                  <span>{{ props.item.company?.displayName }}</span>
                </div>
                <div class="td" :style="`width: ${getColumnWidth('service')}`">
                  {{ props.item.bid.serviceTitle }}
                  <img v-if="props.item.query?.accessibilityRequirements?.wheelchair || props.item.bid?.vehicleFeatures?.wheelChair"
                      title="Tip: filter searching for 'WAV'"
                       src="@/assets/wheelchair.svg"
                       class="ml-2"
                       height="25" />
                </div>
                <div class="td" :style="`width: ${getColumnWidth('status')}`">
                  <span :class="getStatusClass(props.item.status)">{{
                    getStatusLabel(props.item.status)
                  }}</span>
                </div>
                <div class="td" :style="`width: ${getColumnWidth('price')}`">
                  <span v-if="props.item.price">
                    {{ formatPrice(props.item.price) }}
                  </span>
                  <span v-else>
                    <em>{{ formatPrice(props.item.bid.fare.range.max) }}</em>
                  </span>
                </div>
                <div class="td" :style="`width: ${getColumnWidth('startTime')}`">
                  <span v-if="props.item.actualPickupTime">
                    {{ customizeDate(props.item.actualPickupTime) }}
                  </span>
                  <span v-else-if="props.item.expectedPickupTime">
                    <em>
                      {{ customizeDate(props.item.expectedPickupTime) }}
                    </em>
                  </span>
                </div>
                <div class="td" :style="`width: ${getColumnWidth('duration')}`">
                  <span v-if="props.item.actualPickupTime && props.item.actualArrivalTime">
                    {{ differenceBetweenDate(
                      props.item.actualPickupTime,
                      props.item.actualArrivalTime) }}
                  </span>
                  <span v-else-if="!isStatusCanceled(props.item.status) && (props.item.expectedPickupTime && props.item.expectedArrivalTime)">
                    <em>
                      {{ differenceBetweenDate(
                        props.item.expectedPickupTime,
                        props.item.expectedArrivalTime) }}
                    </em>
                  </span>
                  <span v-else>
                    -
                  </span>
                </div>
              </div>
            </fast-table>
          </div>
        </div>
      </div>
    </div>

    <modal-popup v-if="showModal" title="Ridehailing journey" @close="closeModal()" size="fullscreen">
      <template #body>
        <loading
              v-model="isCancelLoading"
              :active="isCancelLoading"
              :is-full-page="false"
              color="#fff"
              background-color="#000"
            />
        <div class="row">
          <div class="col">
            <ridehailing-panel :item="ridehailingSelected" @cancel-booking="cancelBooking(1)"/>
          </div>
        </div>
      </template>
    </modal-popup>

    <modal-popup v-if="showFirstCancelBookingModal" title="Cancel booking" :hide-close="true">
      <template #body>
       <div class="text-center mb-4">
          Are you sure you want to cancel this booking?
        </div>
        <div class="card mb-3 mx-5">
          <ul class="list-group list-group-flush">
            <li class="list-group-item">
              <img
                v-if="ridehailingSelected.company?.visualAssets?.squareLogo?.urlSvg"
                :src="ridehailingSelected.company.visualAssets.squareLogo.urlSvg"
              />
              <span class="ml-2">
                Taxi / Ridehailing
              </span>
              <span style="float:right">
                <span v-if="ridehailingSelected.price">
                  {{ formatPrice(ridehailingSelected.price) }}
                </span>
                <span v-else>
                  {{ formatPrice(ridehailingSelected.bid.fare.range.max) }}
                </span>
              </span>
            </li>
            <li class="list-group-item">
              Cancellation fee
              <strong style="float:right">
                {{ formatPrice(sumCharges(cancellingItem.charge.companyCharges)) }}
              </strong>
            </li>
          </ul>
        </div>
        <div class="text-center mt-4">
          Please, confirm this is the right booking to be cancelled.
        </div>
      </template>
      <template #footer>
        <button class="btn cancel-booking small btn-danger" @click="cancelBooking(2)">
          Yes, cancel it
        </button>
        <button class="btn cancel-booking reverse btn-secondary" @click="showFirstCancelBookingModal = false">
          Not yet
        </button>props.item.query?.accessibilityRequirements?.wheelchair || props.item.bid?.vehicleFeatures?.wheelChair
      </template>
    </modal-popup>

    <modal-popup v-if="showSecondCancelBookingModal" title="Cancel booking" :hide-close="true">
      <template #body>
        <loading
              v-model="isCancelLoading"
              :active="isCancelLoading"
              :is-full-page="false"
              color="#fff"
              background-color="#000"
            />
       <div class="text-center mb-4">
          Make sure you are cancelling the right booking.
        </div>
        <div class="card mb-3 mx-5">
          <ul class="list-group list-group-flush">
            <li class="list-group-item">
              <img
                v-if="ridehailingSelected.company?.visualAssets?.squareLogo?.urlSvg"
                :src="ridehailingSelected.company.visualAssets.squareLogo.urlSvg"
              />
              <span class="ml-2">
                Taxi / Ridehailing
              </span>
              <span style="float:right">
                <span v-if="ridehailingSelected.price">
                  {{ formatPrice(ridehailingSelected.price) }}
                </span>
                <span v-else>
                  {{ formatPrice(ridehailingSelected.bid.fare.range.max) }}
                </span>
              </span>
            </li>
            <li class="list-group-item">
              Cancellation fee
              <strong style="float:right">
                {{ formatPrice(sumCharges(cancellingItem.charge.companyCharges)) }}
              </strong>
            </li>
          </ul>
        </div>
        <div class="text-center mt-4">
          If you are not sure, please confirm the booking details before cancelling it.
        </div>
      </template>
      <template #footer>
        <button class="btn cancel-booking btn-danger" @click="deleteBooking()">
          Cancel booking
        </button>
        <button class="btn cancel-booking btn-secondary" @click="showModals()">
          View booking details
        </button>
      </template>
    </modal-popup>
  </div>
</template>

<script>
import api from "../services/api";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import moment from "moment";
import mixin from "../mixins/mixin";
import sortMixin from "../mixins/sort-mixin";
import downloadMixin from "../mixins/download-mixin";
import DateRange from "../components/DateRange.vue";
import DownloadJson from "../components/DownloadJson.vue";
import DownloadCsv from '../components/DownloadCsv.vue';
import ModalPopup from '../components/ModalPopup.vue';
import RidehailingPanel from '../components/panels/RidehailingPanel.vue';

import FastTable from '../components/FastTable.vue';

const L = window.L;

export default {
  name: "RideHailing",
  components: {
    Loading,
    DateRange,
    DownloadJson,
    DownloadCsv,
    ModalPopup,
    RidehailingPanel,
    FastTable
  },
  mixins: [mixin, sortMixin, downloadMixin],
  data() {
    return {
      isLoading: false,
      rows: [],
      rowsFiltered: [],
      ridehailings: [],
      showFirstCancelBookingModal: false,
      showSecondCancelBookingModal: false,
      ridehailingSelected: {},
      period: "",
      searchText: "",
      dateType: 'book',
      defaultSortField: 'createTime',
      tableCols: [
        { text: "Booked date", direction: "desc", field: "createTime", sortable: true, width: 13 },
        { text: "Full name", field: "fullName", sortable: true, width: 15 },
        { text: "Email", field: "email", sortable: true, width: 17 },
        { text: "Provider", field: "company", sortable: true, width: 11 },
        { text: "Service", field: "service", sortable: true, width: 13 },
        { text: "Status", field: "status", sortable: true, width: 15 },
        { text: "Price", field: "price", sortable: true, width: 7 },
        { text: "Start time", field: "startTime", sortable: true, width: 13 },
        { text: "Duration", field: "duration", sortable: true, width: 9 }
      ],
    };
  },
  created() {
    this.dateRange = { ...this.$store.state.dateRange};
    this.initFilters();
    this.manageCustomerColumn();
  },
  methods: {
    showModalFromLink() {
      if(this.$route.query?.name) {
        const row = this.rowsFiltered.find(item => {
          if(item.name == this.$route.query.name) {
            return item;
          }
        })
        this.onRowClick(row);
      }
    },
    async initFilters() {
      if(! this.$route.params.start) {
        this.$router.push(`/ridehailing/${moment(this.dateRange.start).format("YYYY-MM-DD")}/${moment(this.dateRange.end).format("YYYY-MM-DD")}/${this.dateRange.type}`)
      } else {
        await this.loadData();
        this.showModalFromLink();
      }
    },
    onSort(params) {
      if(params.direction == null) {
        this.rowsFiltered = [ ...this.rows ];
        return;
      }
      if(this.sortingFunctions[params.field]) {
        this.rowsFiltered = this.rowsFiltered.sort(this.sortingFunctions[params.field](params.direction));
      }
    },
    async filterByDateRange(payload) {
      this.dateRange = payload.range;
      this.dateType = payload.type;
      if(this.dateType == "book") {
        this.defaultSortField = "createTime";
      } else {
        this.defaultSortField = "expectedPickupTime";
      }
      this.$store.commit('setDateRange', { start: this.dateRange.start, end: this.dateRange.end, type: this.dateType });
      this.$router.push(`/ridehailing/${moment(this.dateRange.start).format("YYYY-MM-DD")}/${moment(this.dateRange.end).format("YYYY-MM-DD")}/${this.dateType}`);
      this.loadData();
    },
    canCancel(data) {
      return ![
        "COMPLETED",
        "CANCELED_BY_PASSENGER",
        "CANCELED_BY_DRIVER",
        "CANCELED_BY_PROVIDER",
        "AWAITING_FINAL_PRICE",
        "TRAVELING_TO_DESTINATION",
      ].includes(data.status);
    },
    applySearch() {
      clearInterval(this.searchTimeout);
        this.searchTimeout = setTimeout(() => {
        this.rowsFiltered = [...this.rows];

        if(this.searchText.length > 0) {
          this.rowsFiltered = this.rowsFiltered.filter((item) => {
            return this.stringInObject(this.searchText, item);
          });
        }
      }, 250);
    },
    async loadData() {
      this.isLoading = true;
      try {
        const start = this.dateRange
          ? moment(this.dateRange.start).startOf('day').toISOString(true)
          : "";
        const end = this.dateRange
          ? moment(this.dateRange.end).endOf('day').toISOString(true)
          : "";
        const params = new URLSearchParams({
          start, end, date: this.dateType
        });

        const data = await api().get(`/api/ridehailing?${params.toString()}`);
        this.ridehailings = data.data.data;
        this.rows = this.ridehailings;
        this.rows = this.rows.map(item => {
            item.extra = {
                duration1: this.differenceBetweenDate(item.startTime, item.endTime),
                duration2: this.differenceBetweenDate(item.expectedPickupTime, item.expectedArrivalTime),
                price: this.formatPrice(item.price),
                pickup: this.customizeDate(item.expectedPickupTime),
                arrival: this.customizeDate(item.expectedArrivalTime),
                status: this.getStatusLabel(item.status),
                nameSurname: `${item.user?.names?.names.trim()} ${item.user?.surnames?.surnames.trim()}`.trim(),
                accessibility: item.query?.accessibilityRequirements?.wheelchair || item.bid?.vehicleFeatures?.wheelChair ? "WAV" : "",
            };
            return item;
        });
        this.rowsFiltered = [ ...this.rows];
        this.onSort({field: "createTime", direction: "desc"});
      } catch (e) {
        if(e.response?.status == 401 || e.response?.status == 403) {
          return;
        }
        this.$notify({
          type: "error",
          title: "Error",
          text: e.response?.data?.message || e.message
        });
      }
      this.isLoading = false;
    },
    onRowClick(params) {
      this.$router.push({ path: this.$route.path, query: { name: params.name } });
      this.ridehailingSelected = params;
      this.showModal = true;
    },
    showModals() {
      this.showFirstCancelBookingModal = false;
      this.showSecondCancelBookingModal = false;
      this.showModal = true;
    },
    async cancelBooking(step) {
      if (step == 1) {
        this.isCancelLoading = true;
        try {
          const data = await api().delete(`/api/cancel_ridehailing_booking?dry_run=true&id=${this.ridehailingSelected.name}`);
          this.cancellingItem = data.data;
          this.isCancelLoading = false;
          this.showModal = false;

          this.showFirstCancelBookingModal = true;
          this.showSecondCancelBookingModal = false;
        } catch(e) {
          this.isCancelLoading = true;
          this.$notify({
            type: "error",
            title: "Error",
            text:
              e.response && e.response.data
                ? e.response.data.message
                : e.message,
          });
        }
      }
      if (step == 2) {
        this.showFirstCancelBookingModal = false;
        this.showSecondCancelBookingModal = true;
      }
    },
    async deleteBooking() {
      this.isCancelLoading = true;
      try {
        await api().delete(`/api/cancel_ridehailing_booking?id=${this.ridehailingSelected.name}`);
        this.showSecondCancelBookingModal = false;
        this.isCancelLoading = false;
        this.$notify({
            type: "success",
            title: "Confirm",
            text: "Booking cancelled"
          });
      } catch(e) {
        this.$notify({
            type: "error",
            title: "Error",
            text:
              e.response && e.response.data
                ? e.response.data.message
                : e.message,
          });
      }
      this.loadData();
    },
    saveCSV() {
      const data = [
        [
          'Booking time',
          'Full name',
          'Email',
          'Phone number',
          'Provider',
          'Status',
          'Price',
          'Start time',
          'Duration'
        ],
        ...this.rowsFiltered.map(item => {
          return [
            this.customizeDate(item.bid.createTime),
            `${item.user?.names?.names} ${item.user?.surnames?.surnames}`,
            item.user.email?.email,
            item.user?.phoneNumber?.phoneNumber,
            item.company?.displayName || "",
            this.getStatusLabel(item.status),
            item.price ? `${this.price(item)}` : "",
            this.customizeDate(item.startTime),
            this.differenceBetweenDate(item.startTime, item.endTime)
          ]
        })
      ];
      this.downloadBlob("ride_hailing.csv", this.arrayToCsv(data),  "text/csv");
    }
  },
};
</script>

<style scoped>
.td.col-date {
  width: 9%;
}
.td.col-name {
  width: 13%;
}
.td.col-email {
  width: 20%;
}
.td.col-company {
  width: 10%;
}
.td.col-status {
  width: 11%;
}
.td.col-price {
  width: 8%;
}
</style>
