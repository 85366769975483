<template>
    <div class="card border-secondary">
        <div class="card-header bg-secondary">
            Parking
            <i class="fa-solid fa-parking"></i>
        </div>
        <table class="table table-responsive">
          <tbody>
            <tr>
              <th>ID</th>
              <td>
                <object-name :name="parking.name" />
              </td>
            </tr>
            <tr>
              <th>Name</th>
              <td>
                {{ parking.displayName }}
              </td>
            </tr>
            <tr v-if="parking.address">
              <th>Location</th>
              <td>
                {{ parking.address }}
              </td>
            </tr>
            <tr v-if="parking.services">
              <th>Services</th>
              <td>
                <span v-if="parking.services.wheelchairAccess" class="mr-3">
                    <i class="fa-solid fa-wheelchair"></i>
                </span>
                <span v-if="parking.services.bar" class="mr-3">
                  <i class="fa-solid fa-coffee"></i>
                </span>
                <span v-if="parking.services.toilet" class="mr-3">
                  <i class="fa-solid fa-restroom"></i>
                </span>
              </td>
            </tr>
            <tr v-if="parking.pricePerHour">
              <th>Price / h</th>
              <td>
                {{ formatPrice(parking.pricePerHour) }}
              </td>
            </tr>
            <tr v-if="parking.access">
              <th>Access</th>
              <td>
                <span v-if="parking.access.plateReading">
                  Plate reading
                </span>
                <span v-if="parking.access.barrier">
                  Barrier
                </span>
                <span v-if="parking.access.qrCode">
                  QR code
                </span>
              </td>
            </tr>
            <tr v-if="parking.terms.localizedTerms">
              <th>Terms</th>
              <td>
                <p v-for="terms, index in parking.terms.localizedTerms" :key="index" class="mb-0">
                  <a :href="terms.url" target="blank">
                    {{ terms.displayName }}
                  </a>
                </p>
              </td>
            </tr>
            <tr v-if="parking.terms.cancellationPolicies">
              <th>Cancellation policy</th>
              <td>
                <p v-for="policy, index in parking.terms.cancellationPolicies" :key="index" class="mb-0">
                  {{ policy.text }}
                </p>
              </td>
            </tr>
          </tbody>
        </table>
    </div>
</template>

<script>
  import mixin from '../../../mixins/mixin';
  import objectName from './ObjectName.vue';

  export default {
    components: {objectName},
    name: "parkingCard",
    props: ['parking'],
    mixins: [mixin],
    data() {
      return {
      };
    },
    methods: {
    }
  }
</script>

<style>
  table.table {
    margin-bottom: 0
  }
  .card {
    border-width: 1px;
  }
</style>