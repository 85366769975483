<template>
<div>
  <div class="card border-danger">
    <div class="card-header bg-danger">
        Session
        <i class="fa-solid fa-bicycle"></i>
    </div>
    <table class="table">
      <tbody>
        <tr>
          <th>Session ID</th>
          <td>
            <object-name :name="session.name" />
          </td>
        </tr>
        <tr>
          <th>Booked date</th>
          <td>
            <span class="d-inline-block text-truncate" style="max-width: 200px;">
              {{ customizeDate(session.createTime) }}
            </span>
          </td>
        </tr>
        <tr>
          <th>Status</th>
          <td>
            <span :class="getStatusClass(session.status)">
              {{ getStatusLabel(session.status) }}
            </span>
          </td>
        </tr>
        <tr v-if="!showChargesBreakdown">
          <th>Price</th>
          <td>
            {{ formatPrice(session.cost) }}
          </td>
        </tr>
        <tr>
          <th>Start</th>
          <td>{{ customizeDate(session.startTime) }}</td>
        </tr>
        <tr>
          <th>End</th>
          <td>{{ customizeDate(session.endTime) }}</td>
        </tr>
        <tr>
          <th>Duration</th>
          <td>{{ differenceBetweenDate(session.startTime, session.endTime) }}</td>
        </tr>
        <tr>
          <th>Session logs</th>
          <td>
            <table class="table table-responsive table-sm table-borderless">
              <tbody>
                <tr v-for="item, index in session.sessionLogs.filter(item => item.stateTransition?.stateTo)" :key="index">
                  <td>{{ customizeDate(item.timestamp) }}</td>
                  <td>{{item.stateTransition?.stateTo}}</td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
        <tr>
          <th>Iomob vehicle ID</th>
          <td>
            <object-name :name="session.vehicleSelection.vehicleName" />
          </td>
        </tr>
        <tr>
          <th>Vehicle ID</th>
          <td>
            <strong class="d-inline-block text-truncate" style="max-width: 200px;">
              {{ session.vehicles[0].info.freeformVehicleId }}
            </strong>
          </td>
        </tr>
        <tr v-if="session.origin_parking_space">
          <th>Origin station</th>
          <td>
            <strong>
              {{ session.origin_parking_space.displayName }}
            </strong>
            <span v-if="session.origin_parking_space.entrances[0].unstructuredAddress">
              ({{ session.origin_parking_space.entrances[0].unstructuredAddress }})
            </span>
          </td>
        </tr>
        <!--tr>
          <th>Destination station</th>
          <td>
            {{ session.destination_parking_space.displayName }}
            <span v-if="session.destination_parking_space.entrances[0].unstructuredAddress">
              ({{ session.destination_parking_space.entrances[0].unstructuredAddress }})
            </span>
          </td>
        </tr-->
        <tr>
          <th>Tutorial</th>
          <td>
            <span v-for="tutorial, index in session.vehicles[0].info.tutorials" :key="index" class="mb-0">
              <a :href="tutorial.urls[0]" target="blank">
                {{ languageName(tutorial.languageCode) }}
              </a>
            </span>
          </td>
        </tr>
        <tr>
          <th>Picture</th>
          <td>
            <img :src="session.vehicles[0].info.pictureUrl" height="150">
          </td>
        </tr>

        <tr v-if="session.status == 'UNLOCKED'">
          <th></th>
          <td class="d-flex justify-content-end">
            <button
              class="btn cancel-booking btn-danger"
              @click="cancelBooking()"
              >End ride
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <!--div v-for="value, index in session.vehicles" :key="index">
      <p v-for="v, k in value" :key="k">
        {{k}}: {{v}}
      </p>
    </div-->
  </div>
</div>
</template>

<script>
  import mixin from '../../../mixins/mixin';
  import objectName from './ObjectName.vue';

  export default {
    components: { objectName },
    name: "sharedvehicleSessionCard",
    props: ['session'],
    mixins: [mixin],
    data() {
      return {
      };
    },
    methods: {
      cancelBooking() {
        this.$emit('cancel-booking');
      },
      formatVisualAssetCaption(key) {
        return key.replace("url", "").replace("Svg", "SVG");
      },
    }
  }
</script>

<style>
  table.table {
    margin-bottom: 0
  }
  .card {
    border-width: 1px;
  }
</style>