<template>
    <div class="container-columns">
        <table-header-cell 
            :text="col.text" 
            :width="col.width"                   
            :key="index"
            :sortable="col.sortable"
            :direction="col.direction"
            :field="col.field"
            v-for="(col, index) in realColumns" 
            @click="handleClick(col.field)"
        />
    </div>
</template>

<script>
const directions = [null, 'asc', 'desc'];

import TableHeaderCell from './TableHeaderCell.vue';
export default {
    components: { TableHeaderCell },
    props: ['columns', 'onSort', 'initialSortBy'],
    data() {
        return {
            directionCounter: 0,                
            /**
             * null => no arrows selected
             * asc => top arrow selected
             * desc => bottom arrow selected
             */
            direction: directions[0],
            classObject: {
                'fa-solid': true,
                'arrow': true,
            },
            currentSortField: null,
            realColumns: [],
        }            
    }, 
    created() {
        for(let i = 0; i < directions.length; i++) {
            if(this.direction == directions[i]) {
                this.direction = directions[i];
            }
        }
        this.realColumns = [ ...this.columns];
    },    
    methods: {
        reset() {
            this.currentSortField = this.initialSortBy;
            this.directionCounter = 0;
            this.refreshCols(this.currentSortField);
        },
        refreshCols(field) {
            this.realColumns = this.realColumns.map(item => {
                if(item.field == field) {
                    item.direction = this.direction;
                } else {
                    item.direction = null;
                }
                return item;
            });
        },
        handleClick(field) {
            if(this.currentSortField != field) {
                this.directionCounter = 0;
            }
            this.currentSortField = field;
            this.direction = directions[++this.directionCounter % directions.length];
            this.refreshCols(field);

            const column = this.realColumns.find(item => item.field == field);
            if(column.sortable) {
                this.$emit('sort', { field: field, direction: this.direction });
            }          
        }
    }
}
</script>

<style>

</style>