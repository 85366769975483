<template>
    <div class="d-flex flex-nowrap">
        <input class="form-control date-filter" placeholder="Filter by date" ref="datepicker" />
        <select class="form-control ml-1" v-model="localType">
            <option value="book">Booked date</option>
            <option value="service">Service date</option>
        </select>
    </div>
</template>

<script>
import { easepick } from '@easepick/core';
import { RangePlugin } from '@easepick/range-plugin';
import { PresetPlugin } from '@easepick/preset-plugin';

export default {
    name: "DateRange",
    props: ['startDate', 'endDate', 'type'],
    data() {
        return {
            picker: null,
            localType: null,
        }
    },
    mounted() {
        const instance = this;
        this.picker = new easepick.create({
        element: this.$refs.datepicker,
            css: [
                'https://cdn.jsdelivr.net/npm/@easepick/core@1.1.7/dist/index.css',
                'https://cdn.jsdelivr.net/npm/@easepick/range-plugin@1.1.7/dist/index.css',
                'https://cdn.jsdelivr.net/npm/@easepick/preset-plugin@1.1.7/dist/index.css',
            ],
            plugins: [RangePlugin, PresetPlugin],
            zIndex: 10,
            RangePlugin: {
                startDate: this.startDate,
                endDate: this.endDate
            },
            setup(picker) {
                picker.on('select', (e) => {
                    instance.$emit('changed', { range: e.detail, type: instance.localType })
                });
            },
        });
        this.localType = this.type;
    },
    watch: {
        localType(newValue, oldValue) {
            if(oldValue != null) {
                this.$emit('changed', { range: {
                    start: this.picker.getStartDate(),
                    end: this.picker.getEndDate(),
                }, type: this.localType });
            }
        }
    }
}
</script>

<style scoped>
.date-filter[disabled],
.date-filter[readonly] {
    background: #fff;
}
select {
    width: 140px;
}
</style>
