<template>
    <div class="table-col" :class="sortable ? 'pointer' : null" :style="`width: ${width}%`">
        {{ text }}
        <div class="container-arrows" v-if="sortable">
            <span :class="{ active : direction == 'asc' }">
                <i class="fa-solid fa-chevron-up"></i>
            </span>
            <span :class="{ active : direction == 'desc' }">
                <i class="fa-solid fa-chevron-down"></i>
            </span>
        </div>
    </div>
</template>

<script>
    export default {
        name: "companyCharges",
        props: ['text', 'width', 'sortable', 'direction', 'field'],
        data() {
            return {
                classObject: {
                    'fa-solid': true,
                    'arrow': true,
                }
            }            
        }, 
    }
</script>

<style scoped>
    .table-col {
        display: flex;
        padding: 12px 0 12px 1px;
        box-sizing: border-box;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
        white-space: nowrap;
        justify-content: space-between;
        user-select: none;
    }
    .table-col:first-of-type {
        padding-left: 4px;
    }
    .container-arrows {
        flex-direction: column;
        display: flex;
        justify-content: center;
        margin-right:10px;
    }
    span {
        font-size: 10px;
        position: relative;
        line-height: 1;
    }
    span:first-of-type {
        bottom: -2px;
    }
    span:last-of-type {
        top: -2px;
    }
    .active {
        color: #409eff;
    }
    .pointer {
        cursor: pointer;
    }
</style>