import axios from 'axios';
import router from '../router';
import store from '../store'

export default() => {
    var headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    }

    var api = axios.create({
        baseURL: process.env.VUE_APP_BASE_API_URL,
        headers: headers
    })
    api.interceptors.request.use(
        config => {
            config.headers.token = store.getters.token;
            config.headers["x-iomob-domain"] = store.getters.domain;
            return config;
        },
        error => Promise.reject(error)
    )
    api.interceptors.response.use(undefined, function (error) {
        if (error) {
            const originalRequest = error.config;
            if ((error.response.status === 401 || error.response.status === 403) &&
                !originalRequest._retry) {
                    originalRequest._retry = true;
                    store.dispatch('logOut')
                    return router.push('/login')
            }

        }
    })

    return api;
}
