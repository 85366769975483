<template>
    <div class="card border-danger">
        <div class="card-header bg-danger">
            Booking
            <i class="fa-solid fa-ticket"></i>
        </div>
        <table class="table">
          <tbody>
            <tr>
              <th>ID</th>
              <td>
                <object-name :name="booking.name" />
              </td>
            </tr>
            <tr>
              <th>Provider ID</th>
              <td>
                <object-name :name="booking.reference" />
              </td>
            </tr>
            <tr>
              <th>Status</th>
              <td>
                <span :class="getStatusClass(booking.status)">
                  {{ getStatusLabel(booking.status) }}
                </span>
              </td>
            </tr>
            <tr v-if="!showChargesBreakdown">
              <th>Actual fare</th>
              <td>
                {{ formatPrice(booking.price) }}
              </td>
            </tr>

            <tr>
              <th>Pick up address</th>
              <td>
                {{ booking.actualOrigin.unstructuredAddress }}
              </td>
            </tr>
            <tr>
              <th>Drop off address</th>
              <td>
                {{ booking.actualDestination.unstructuredAddress }}
              </td>
            </tr>

            <tr>
              <th>Pick up time</th>
              <td>
                <p v-if="booking.expectedPickupTime">
                  <b>Expected:</b> {{ customizeDate(booking.expectedPickupTime) }}
                </p>
                <p v-if="booking.actualPickupTime">
                  <b>Actual:</b> {{ customizeDate(booking.actualPickupTime) }}
                  <span v-if="booking.expectedPickupTime && booking.actualPickupTime">
                    ({{ differenceBetweenDate(booking.expectedPickupTime, booking.actualPickupTime, true) }})
                  </span>
                </p>
              </td>
            </tr>

            <tr>
              <th>Drop off time</th>
              <td>
                <p v-if="booking.expectedArrivalTime">
                  <b>Expected:</b> {{ customizeDate(booking.expectedArrivalTime) }}
                </p>
                <p v-if="booking.actualArrivalTime">
                  <b>Actual:</b> {{ customizeDate(booking.actualArrivalTime) }}
                  <span v-if="booking.expectedArrivalTime && booking.actualArrivalTime">
                    ({{ differenceBetweenDate(booking.expectedArrivalTime, booking.actualArrivalTime, true) }})
                  </span>
                </p>
              </td>
            </tr>

            <tr>
              <th>Duration</th>
              <td>
                <p v-if="booking.expectedPickupTime && booking.expectedArrivalTime">
                  <b>Expected:</b> {{ differenceBetweenDate(
                  booking.expectedPickupTime,
                  booking.expectedArrivalTime) }}
                </p>
                <p v-if="booking.actualPickupTime && booking.actualArrivalTime">
                  <b>Actual:</b> {{ differenceBetweenDate(
                  booking.actualPickupTime,
                  booking.actualArrivalTime) }}
                </p>
              </td>
            </tr>

            <tr v-if="booking.driver?.phoneNumber">
              <th>
                Driver
              </th>
              <td>
                <div class="row">
                  <div class="">
                    <img v-if="booking.driver.pictureUrl" :src="booking.driver.pictureUrl" height="100" class="mb-4 mr-5 rounded float-left" />
                  </div>
                  <div class="">
                    <p v-if="booking.driver.displayName">
                      <strong>Name</strong>: {{ booking.driver.displayName }}
                    </p>
                    <p v-if="booking.driver.phoneNumber">
                      <strong>Phone number</strong>: {{ booking.driver.phoneNumber }}
                    </p>
                    <p v-if="booking.driver.supportNumber">
                      <strong>Support number</strong>: {{ booking.driver.supportNumber }}
                    </p>
                    <p v-if="booking.driver.serviceLanguages">
                      <strong>Languages</strong>:
                      {{ booking.driver.serviceLanguages.map(lang => languageName(lang)).join(', ') }}
                    </p>
                  </div>
                </div>
              </td>
            </tr>

            <tr v-if="booking.vehicle">
              <th>
                Vehicle
              </th>
              <td>
                <div class="row">
                  <div class="">
                    <p v-if="booking.vehicle.make">
                      <strong>Make</strong>: {{ booking.vehicle.make }}
                    </p>
                    <p v-if="booking.vehicle.model">
                      <strong>Model</strong>: {{ booking.vehicle.model }}
                    </p>
                    <p v-if="booking.vehicle.licensePlate">
                      <strong>License plate</strong>: {{ booking.vehicle.licensePlate }}
                    </p>
                  </div>
                </div>
              </td>
            </tr>

          </tbody>
        </table>
    </div>
</template>

<script>
  import mixin from '../../../mixins/mixin';
  import objectName from './ObjectName.vue';
  export default {
    components: { objectName },
    name: "ridehailingBookingCard",
    props: ['booking'],
    mixins: [mixin],
    data() {
      return {
      };
    },
    methods: {
    }
  }
</script>

<style>
  table.table {
    margin-bottom: 0
  }
  .card {
    border-width: 1px;
  }
  p {
    margin-bottom: 0 !important;
  }

</style>