<template>
    <div class="card border-secondary">
        <div class="card-header bg-secondary">
            Bid
            <i class="fa-solid fa-taxi"></i>
        </div>
        <table class="table">
          <tbody>
            <tr>
              <th>ID</th>
              <td>
                <object-name :name="bid.name" />
              </td>
            </tr>
            <tr>
              <th>Service Title</th>
              <td>{{ bid.serviceTitle }}</td>
            </tr>
            <tr>
              <th>Vehicle Features</th>
              <td>
                <span v-if="bid.vehicleFeatures.capacity?.maxPassengers" class="mr-3">
                  {{ bid.vehicleFeatures.capacity?.maxPassengers }}
                  <i class="fa-solid fa-person"></i>
                </span>
                <span v-if="bid.vehicleFeatures.capacity?.childSeatCount" class="mr-3">
                  {{ bid.vehicleFeatures.capacity?.childSeatCount }}
                  <i class="fa-solid fa-baby"></i>
                </span>
                <span v-if="bid.vehicleFeatures.capacity?.maxLuggage != -1" class="mr-3">
                  {{ bid.vehicleFeatures.capacity?.maxLuggage }}
                  <i class="fa-solid fa-suitcase"></i>
                </span>
                <span v-if="bid.vehicleFeatures.wheelChair" class="mr-3">
                  <i class="fa-solid fa-wheelchair"></i>
                </span>
              </td>
            </tr>
            <tr>
              <th>Terms</th>
              <td>
                <p v-for="terms, index in bid.terms.localizedTerms" :key="index" class="mb-0">
                  <a :href="terms.url" target="blank">
                    {{ terms.displayName }}
                  </a>
                </p>
              </td>
            </tr>
            <tr v-if="bid.terms.cancellationPolicies">
              <th>Cancellation policy</th>
              <td>
                <p v-for="policy, index in bid.terms.cancellationPolicies" :key="index" class="mb-0">
                  {{ policy.text }}
                </p>
              </td>
            </tr>
            <tr>
              <th>Pick up</th>
              <td>{{ PickupMechanisms[bid.pickup?.mechanism] }}</td>
            </tr>
            <tr>
              <th>Estimated fare</th>
              <td>
                <span class="text-capitalize">
                  {{ bid.fare.fareType.toLowerCase() }}:
                </span>
                <span v-if="fareHasRange(bid.fare.range)">
                  {{ formatPrice(bid.fare.range.min) }}
                  &ndash;
                  {{ formatPrice(bid.fare.range.max) }}
                </span>
                <span v-else>
                  {{ formatPrice(bid.fare.range.min) }}
                </span>
              </td>
            </tr>
            <tr>
              <th>Requested pick up</th>
              <td>
                {{ customizeDate(bid.expectedPickupTime) }}
                <span v-if="bid.expectedPickupTime != bid.pickupTimeInterval.startTime || bid.expectedPickupTime != bid.pickupTimeInterval.endTime">
                  {{ customizeDate(bid.pickupTimeInterval.startTime) }}
                  &ndash;
                  {{ customizeDate(bid.pickupTimeInterval.endTime) }}
                </span>
              </td>
            </tr>
            <tr v-if="bid.expectedArrivalTime">
              <th>Requested arrival</th>
              <td>{{ customizeDate(bid.expectedArrivalTime) }}</td>
            </tr>
            <tr v-if="canCancel(bid-status)">
              <th></th>
              <td class="d-flex justify-content-end">
                <button
                  class="btn btn-danger cancel-booking"
                  @click="cancelBooking()"
                >
                  Cancel booking
                </button>
              </td>
            </tr>
            <!--tr>
              <th>actualOrigin</th>
              <td>{{bid.actualOrigin}}</td>
            </tr>
            <tr>
              <th>actualDestination</th>
              <td>{{bid.actualDestination}}</td>
            </tr-->
          </tbody>
        </table>
    </div>
</template>

<script>
  import mixin from '../../../mixins/mixin';
  import objectName from './ObjectName.vue';
  export default {
    components: { objectName },
    name: "ridehailingBidCard",
    props: ['bid', 'bid-status'],
    mixins: [mixin],
    data() {
      return {
        PickupMechanisms: {
          PICKUP_MECHANISM_UNSPECIFIED: "Unspecified",
          MEET_AND_GREET: "Meet and greet",
          CURBSIDE: "Curbside",
          STANDBY: "Stand by",
        },
      };
    },
    methods: {
      cancelBooking() {
        this.$emit('cancel-booking');
      },
      canCancel(data) {
        return ![
          "COMPLETED",
          "CANCELED_BY_PASSENGER",
          "CANCELED_BY_DRIVER",
          "CANCELED_BY_PROVIDER",
        ].includes(data);
      },
      formatVisualAssetCaption(key) {
        return key.replace("url", "").replace("Svg", "SVG");
      },
      fareHasRange(range) {
        const a = range.min;
        const b = range.max;
        return a.currencyCode != b.currencyCode || a.units != b.units || a.nanos != b.nanos;
      }
    }
  }
</script>

<style>
  table.table {
    margin-bottom: 0
  }
  .card {
    border-width: 1px;
  }
</style>
