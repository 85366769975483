import { createStore } from 'vuex';
import { getAuth, signOut } from "firebase/auth";
import * as firebase from "firebase/app";
import config from '../config';
import moment from 'moment';

const store = createStore({
    state: {
        user: null,  
        dateRange: {
            start: moment().toDate(),
            end: moment().toDate(),
            type: 'book'
        }
    },
    getters: {
        isAuthenticated: state => !!state.user, 
        stateUser: state => state.user,
        token: state => state.user?.token,
        domain: state => state.user?.domain,
        hasCustomerColumn: state => {
            if(! state.user?.domain) {
                return false;
            }
            return !! config.customers[state.user?.domain].customerColumn;
        },
        showChargesBreakdown: state => {
            return !! config.customers[state.user?.domain].showChargesBreakdown;
        }
    },
    actions: {
        logOut({ commit }) {
            commit('logOut')
        }
    },
    mutations: {
        setDateRange(state, data) {
            state.dateRange.start = data.start;
            state.dateRange.end = data.end;
            state.dateRange.type = data.type;
        },
        setUser(state, data) {
            // console.log(`Vuex: setUser`, data);
            state.user = data;
        },
        logOut(state) {
            // console.log(`Vuex: logOut`, state.user);
            const conf = config.firebase.find(c => c.domain == this.getters.domain);
            const app = firebase.initializeApp(conf.configuration, conf.name);
            const auth = getAuth(app);
            state.user = null;
            signOut(auth).then(() => {
                console.info("Firebase: Sign out");
            }).catch((error) => {
                console.log("Firebase error", error);
            });            
        }
    }
});

export default store;