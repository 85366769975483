<template>
  <div class="align-items-center justify-content-center">
      <div class="login-box">
          <div class="text-center mb-4">
              <img src="@/assets/logo.svg" alt="Logo Iomob" />
          </div>
          <div class="card">
              <div class="card-body login-card-body">
                <p v-if="sent">
                    Recovery link sent to your email address.
                    <br>
                    <br>
                    Please check your spam folder if you don't receive it briefly.
                </p>
                <div v-else>
                  <p class="login-text">
                    Get a password reset link in your email
                  </p>
                  <div class="mb-3">
                      <input type="email" class="form-control" placeholder="Email" autocomplete="off" v-model="email">
                  </div>
                  <p v-if="errorMessage" class="alert alert-warning">{{ errorMessage }}</p>
                  <div class="d-flex justify-content-end">
                      <button class="btn btn-primary" @click="recoverPassword()">
                        Recover password
                      </button>
                  </div>
                </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import config from '../config';
import * as firebase from "firebase/app";

export default {
  name: "UserLogin",
  data() {
      return {
          email: "",
          sent: false,
          errorMessage: null,
          firebaseApps: {}
      }
  },
  created() {
      config.firebase.forEach(conf => {
          this.firebaseApps[conf.domain] = {
              app: firebase.initializeApp(conf.configuration, conf.name),
              conf: conf
          }
      });
  },
  methods: {
      recoverPassword() {
          this.errorMessage == null;
          if(!(this.email && this.email.indexOf('@') > 0)) {
              this.errorMessage = "Enter email";
              return;
          }

          const domain = this.email.split('@')[1].toLowerCase();
          if(! this.firebaseApps[domain]) {
              this.errorMessage = "Email not allowed";
              return;
          }

          const auth = getAuth(this.firebaseApps[domain].app);

          var nextURL = window.location.href.replace("recover-password", "login");
          sendPasswordResetEmail(auth, this.email, {url: nextURL})
          .then((response) => {
            console.log("The email was sent successfully");
            this.sent = true;
          })
          .catch(error => {
              if(error.code == "auth/user-not-found") {
                  this.errorMessage = "Invalid email";
              } else if(error.code == "auth/invalid-email") {
                  this.errorMessage = "Invalid email";
              } else {
                  this.errorMessage = error.message;
          }
          });
      },
  },
  mounted () {
      document.body.classList.add('page-login');
  },
  unmounted () {
      document.body.classList.remove('page-login');
  },
}
</script>

<style lang="scss" scoped>
@import "../css/colors";
  .login-text {
      color: $background;
      font-size: 18px;
  }
  .login-box {
      color: $background !important;
      margin: auto;
  }
</style>
