<template>
  <div>
    <loading
      v-model="isLoading"
      :active="isLoading"
      :is-full-page="true"
      color="#fff"
      background-color="#000"
    />

    <div class="content">
      <div class="container-fluid pt-2">
        <div class="card">
          <div class="card-header bg-info align-middle ">
            <h1 class="card-title">Shared mobility</h1>
            <div class="card-tools">
              <div class="d-flex justify-content-end align-items-center">
                <date-range
                  @changed="filterByDateRange"
                  :start-date="dateRange.start"
                  :end-date="dateRange.end"
                  :type="dateRange.type"
                />
                <i></i>
                <input
                  type="search"
                  @keyup="applySearch(true)"
                  class="form-control"
                  v-model="searchText"
                  placeholder="Search..."
                />
                <i></i>
                <download-json :data="rowsFiltered" filename="sharedvehicles.json" />
                <download-csv @click="saveCSV()" />
              </div>
            </div>
          </div>
          <div class="card-body">
            <fast-table :items="rowsFiltered" :columns="tableCols" v-slot="props" @sort="onSort" :defaultSortField="defaultSortField" styleClass="micromobility">
              <div class="dynamic-item" @click="onRowClick(props.item)">
                <div class="td text-truncate" :style="`width: ${getColumnWidth('createTime')}`">{{ customizeDate(props.item.createTime) }}</div>
                <div class="td" v-if="hasCustomerColumn" :style="`width: ${getColumnWidth('client')}`">
                  {{ getCustomerName(props.item) }}
                </div>
                <div class="td text-truncate" :style="`width: ${getColumnWidth('fullName')}`">
                  <span class="mr-1"
                    :class="notValidatedClass(props.item.user?.names)"
                  >{{ props.item.user?.names?.names }}</span>
                  <span :class="notValidatedClass(props.item.user?.surnames)">{{
                    props.item.user?.surnames?.surnames
                  }}</span>
                </div>
                <div class="td text-truncate" :style="`width: ${getColumnWidth('email')}`">
                  <span :class="notValidatedClass(props.item.user.email)">{{
                    props.item.user.email?.email
                  }}</span>
                </div>
                <div class="td text-truncate" :style="`width: ${getColumnWidth('phoneNumber')}`">
                  <span
                    :class="notValidatedClass(props.item.user?.phoneNumber)"
                    >{{ props.item.user?.phoneNumber?.phoneNumber }}</span
                  >
                </div>
                <div class="td text-truncate" :style="`width: ${getColumnWidth('company')}`">
                  <img
                    v-if="props.item?.company?.visualAssets?.squareLogo?.urlSvg"
                    :src="props.item.company.visualAssets.squareLogo.urlSvg"
                  />
                  &nbsp;
                  <span>{{ props.item.company?.displayName }}</span>
                </div>
                <div class="td" :style="`width: ${getColumnWidth('vehicle')}`">
                  <span v-for="(type, index) in props.item.vehicles.slice(0, 1)" :key="index" style="text-transform: capitalize">
                    <i :class="`${vehicleIcon(type.info.type)}`" v-if="type?.info?.type"></i>
                    &nbsp;
                    <span>{{ vehicleName(type.info.type) }}</span>
                  </span>
                </div>
                <div class="td" :style="`width: ${getColumnWidth('status')}`">
                  <span :class="getStatusClass(props.item.status)">{{
                    getStatusLabel(props.item.status)
                  }}</span>
                </div>
                <div class="td" :style="`width: ${getColumnWidth('price')}`">
                  {{ formatPrice(props.item.cost) }}
                </div>
                <!--div class="td" :style="`width: ${getColumnWidth('stripe')}`">
                  <span :class="'badge badge-' + getStripeBadgeClass()">
                    {{ getStripelabel(props.item.payment_intents) }}
                  </span>
                </div-->
                <div class="td text-truncate" :style="`width: ${getColumnWidth('startTime')}`">
                  {{ customizeDate(props.item.startTime) }}
                </div>
                <div class="td" :style="`width: ${getColumnWidth('duration')}`">
                  {{ differenceBetweenDate(props.item.startTime, props.item.endTime) }}
                </div>
              </div>
            </fast-table>
          </div>
        </div>
      </div>
    </div>
    <modal-popup  v-if="showModal"
      title="Shared mobility session"
      size="fullscreen"
      @close="closeModal()">
      <template #body>
        <div class="row">
          <div class="col">
            <micromobility-panel :item="micromobilitySelected" @cancel-booking="cancelBooking(1)" />
          </div>
        </div>
      </template>
    </modal-popup>

    <modal-popup v-if="showFirstCancelBookingModal"
      title="End ride"
      @close="showFirstCancelBookingModal = false"
       :hide-close="true">
      <template #body>
        <div class="d-flex justify-content-center mb-3">
              <span>Are you sure you want to end this ride?</span>
            </div>
            <div class="d-flex justify-content-center">
              <span>Please, confirm this is the right ride to be ended.</span>
            </div>
      </template>
      <template #footer>
        <button
              class="btn cancel-booking small btn-danger"
              @click="cancelBooking(2)"
            >
              Yes, end it
            </button>
            <button
              class="btn cancel-booking reverse btn-secondary"
              @click="showFirstCancelBookingModal = false"
            >
              Not yet
            </button>
      </template>
    </modal-popup>

    <modal-popup v-if="showSecondCancelBookingModal"
      title="End ride"
      @close="showModals()"
      :hide-close="true">
      <template #body>
        <loading
              v-model="isCancelLoading"
              :active="isCancelLoading"
              :is-full-page="false"
              color="#fff"
              background-color="#000"
            />
        <div class="d-flex justify-content-center mb-3">
          <span>Make sure you are finishing the right ride. </span>
        </div>
        <div class="d-flex justify-content-center">
          <span class="text-center">If you are not sure, please confirm the ride details before finishing it.</span
          >
        </div>
      </template>
      <template #footer>
        <button
          class="btn cancel-booking btn-danger"
          @click="deleteBooking()">
          End ride
        </button>
        <button
          class="btn cancel-booking btn-secondary"
          @click="showModals()">
          View ride details
        </button>
      </template>
    </modal-popup>
  </div>
</template>

<script>
import api from "../services/api";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import moment from "moment";
import mixin from "../mixins/mixin";
import sortMixin from "../mixins/sort-mixin";
import downloadMixin from "../mixins/download-mixin";
import DateRange from "../components/DateRange.vue";
import DownloadJson from "../components/DownloadJson.vue";
import DownloadCsv from '../components/DownloadCsv.vue';
import ModalPopup from '../components/ModalPopup.vue';
import MicromobilityPanel from '../components/panels/MicromobilityPanel.vue';
import FastTable from '../components/FastTable.vue';

const L = window.L;

export default {
  name: "MicroMobility",
  components: {
    Loading,
    DateRange,
    DownloadJson,
    DownloadCsv,
    ModalPopup,
    MicromobilityPanel,
    FastTable
  },
  mixins: [mixin, sortMixin, downloadMixin],
  data() {
    return {
      isLoading: false,
      rows: [],
      rowsFiltered: [],
      micromobility: [],
      micromobilitySelected: [],
      showModal: false,
      searchText: "",
      showFirstCancelBookingModal: false,
      showSecondCancelBookingModal: false,
      dateType: 'book',
      defaultSortField: 'createTime',
      tableCols: [
        { text: "Booked date", direction: "desc", field: "createTime", sortable: true, width: 11 },
        { text: "Full name", field: "fullName", sortable: true, width: 13 },
        { text: "Email", field: "email", sortable: true, width: 18 },
        { text: "Phone", field: "phoneNumber", sortable: true, width: 11 },
        { text: "Provider", field: "company", sortable: true, width: 10 },
        { text: "Vehicle", field: "vehicle", sortable: true, width: 8 },
        { text: "Status", field: "status", sortable: true, width: 6 },
        { text: "Price", field: "price", sortable: true, width: 6 },
        //{ text: "Stripe", field: "stripe", sortable: true, width: 4 },
        { text: "Start time", field: "startTime", sortable: true, width: 11 },
        { text: "Duration", field: "duration", sortable: true, width: 7 }
      ],
    };
  },
  created() {
    this.dateRange = { ...this.$store.state.dateRange};
    this.initFilters();
    this.manageCustomerColumn();
  },
  updated() {
    /*console.log(this.$route)
    if(this.$route.fullPath != this.currentPath) {
      this.currentPath = this.$route.fullPath;
      this.initFilters();
    }*/
  },
  methods: {
    showModalFromLink() {
      if(this.$route.query?.name) {
        const row = this.rowsFiltered.find(item => {
          if(item.name == this.$route.query.name) {
            return item;
          }
        })
        this.onRowClick(row);
      }
    },
    async initFilters() {
      if(! this.$route.params.start) {
        this.$router.push(`/micromobility/${moment(this.dateRange.start).format("YYYY-MM-DD")}/${moment(this.dateRange.end).format("YYYY-MM-DD")}/${this.dateRange.type}`)
      } else {
        await this.loadData();
        this.showModalFromLink();
      }
    },
    async loadData() {
      this.isLoading = true;
      try {
        const start = this.dateRange
          ? moment(this.dateRange.start).startOf('day').toISOString(true)
          : "";
        const end = this.dateRange
          ? moment(this.dateRange.end).endOf('day').toISOString(true)
          : "";
        const params = new URLSearchParams({
          start, end, date: this.dateType
        });
        const data = await api().get(`/api/micromobility?${params.toString()}`);
        this.micromobility = data.data.data;
        this.rows = this.micromobility;
        this.rows = this.rows.map(item => {
            item.extra = {
              duration: this.differenceBetweenDate(item.startTime, item.endTime),
              price: this.price(item),
              start: this.customizeDate(item.startTime),
              end: this.customizeDate(item.endTime),
              status: this.getStatusLabel(item.status),
              nameSurname: `${item.user?.names?.names.trim()} ${item.user?.surnames?.surnames.trim()}`.trim()
            };
            return item;
        })
        this.rowsFiltered = [ ...this.rows];
        this.onSort({field: "createTime", direction: "desc"});
      } catch (e) {
        if(e.response?.status == 401 || e.response?.status == 403) {
          return;
        }
        console.log(e)
        this.$notify({
          type: "error",
          title: "Error",
          text: e.response?.data?.message || e.message
        });
      }
      this.isLoading = false;
    },
    onSort(params) {
      if(params.direction == null) {
        this.rowsFiltered = [ ...this.rows ];
        this.applySearch(false);
        return;
      }
      if(this.sortingFunctions[params.field]) {
        this.rowsFiltered = this.rowsFiltered.sort(this.sortingFunctions[params.field](params.direction));
      }
    },
    showModals() {
      this.showFirstCancelBookingModal = false;
      this.showSecondCancelBookingModal = false;
      this.showModal = true;
    },
    cancelBooking(step) {
      this.showModal = false;
      if (step == 1) {
        this.showFirstCancelBookingModal = true;
        this.showSecondCancelBookingModal = false;
      }
      if (step == 2) {

        this.showFirstCancelBookingModal = false;
        this.showSecondCancelBookingModal = true;
      }
    },
    async deleteBooking() {
      this.isCancelLoading = true;
      try {
        await api().delete(`/api/cancel_sharedvehicle_session?id=${this.micromobilitySelected.name}`);
        this.showSecondCancelBookingModal = false;
        this.isCancelLoading = false;
        this.$notify({
            type: "success",
            title: "Confirm",
            text: "Session cancelled"
          });
      } catch(e) {
        this.$notify({
            type: "error",
            title: "Error",
            text:
              e.response && e.response.data
                ? e.response.data.message
                : e.message,
          });
      }

      this.loadData();
    },
    async filterByDateRange(payload) {
      this.dateRange = payload.range;
      this.dateType = payload.type;
      if(this.dateType == "book") {
        this.defaultSortField = "createTime";
      } else {
        this.defaultSortField = "startTime";
      }
      this.$store.commit('setDateRange', { start: this.dateRange.start, end: this.dateRange.end, type: this.dateType });
      this.$router.push(`/micromobility/${moment(this.dateRange.start).format("YYYY-MM-DD")}/${moment(this.dateRange.end).format("YYYY-MM-DD")}/${this.dateType}`);
      this.loadData();
    },
    applySearch(delay) {
      if(delay === undefined) {
        delay = true;
      }

      const _applySearch = () => {
        if (this.searchText.length > 0) {
          const data = [...this.rows];
          const s1 = moment();
          this.rowsFiltered = data.filter((item) => {
            return this.stringInObject(this.searchText, item);
          });
          const s2 = moment();
          console.log(`Search took: ${s2.diff(s1, 'milliseconds')}ms`)
        } else {
          this.rowsFiltered = [...this.rows];
        }
      };

      clearInterval(this.searchTimeout);
      if(delay) {
        this.searchTimeout = setTimeout(_applySearch, 250);
      } else {
        _applySearch();
      }
    },
    price(row) {
      if(! row.cost) {
        return "";
      }
      var x = 0.0;
      if ("units" in row.cost) {
        x += parseInt(row.cost.units);
      }
      if ("nanos" in row.cost) {
        x += parseInt(row.cost.nanos) / 1e9;
      }
      return x.toFixed(2);
    },
    onRowClick(row) {
      this.$router.push({ path: this.$route.path, query: { name: row.name } });
      this.micromobilitySelected = row;
      this.showModal = true;
      const coordinates = this.micromobilitySelected.vehicles.map((item) => {
        return [item.currentPosition.latitude, item.currentPosition.longitude];
      });
    },
    showMap() {
      if (this.micromobilitySelected.vehicles[0].currentPosition) {
        return true;
      }
      return false;
    },
    getStripeBadgeClass() {
      return "success";
    },
    getStripelabel() {
      return "hello";
    },
    filters() {
      this.rowsFiltered = [...this.rows];
      if (this.period) {
        let dateBefore = moment();
        if (this.period == "w") {
          dateBefore = dateBefore.subtract(7, "days");
        }
        if (this.period == "ww") {
          dateBefore = dateBefore.subtract(14, "days");
        }
        this.rowsFiltered = this.rows.filter((item) =>
          moment(item.endTime).isAfter(dateBefore)
        );
      }
    },
    saveCSV() {
      const data = [
        [
          'Booking time',
          'Full name',
          'Email',
          'Phone number',
          'Company',
          'Vehicles',
          'Status',
          'Total price',
          'Start time',
          'Duration'
        ],
        ...this.rowsFiltered.map(item => {
          return [
            this.customizeDate(item.createTime),
            `${item.user?.names?.names} ${item.user?.surnames?.surnames}`,
            item.user.email?.email,
            item.user?.phoneNumber?.phoneNumber,
            item.company?.displayName || "",
            item.vehicles.map(vehicle => this.vehicleName(vehicle.info.type)).join(" "),
            this.getStatusLabel(item.status),
            item.cost ? `${this.getCurrencyLabel(item.cost?.currencyCode)} ${this.price(item)}` : "",
            this.customizeDate(item.startTime),
            this.differenceBetweenDate(item.startTime, item.endTime)
          ]
        })
      ];
      this.downloadBlob("sharedvehicles.csv", this.arrayToCsv(data),  "text/csv");
    }
  },
};
</script>

<style scoped>
.user {
  height: 32%;
  padding: 0 12px;
  display: flex;
  align-items: center;
}
</style>
