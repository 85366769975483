const downloadMixin = {
    methods: {
        downloadBlob(filename, data, mimeType) {
            const blob = new Blob([data], { type: mimeType });
            const link = document.createElement("a");

            link.download = filename;
            link.href = window.URL.createObjectURL(blob);
            link.dataset.downloadurl = ["text/json", link.download, link.href].join(":");

            const evt = new MouseEvent("click", {
                view: window,
                bubbles: true,
                cancelable: true,
            });

            link.dispatchEvent(evt);
            link.remove()
        }
    }
}

export default downloadMixin;