<template>
  <div class="wrapper">
    <notifications />
    <template v-if="ready">
      <SideBar id="sidebar" />
      <main class="content-wrapper">
        <router-view :key="$route.name"></router-view>
      </main>
    </template>
    <template v-else>
      <div class="d-flex align-items-center justify-content-center">
          <div class="login-box text-center">
              <div class="text-center mb-4">
                  <img src="@/assets/logo.svg" alt="Logo Iomob" />
              </div>
              <i class="loading fa-spin fa-solid fa-circle-notch"></i>
          </div>
      </div>      
    </template>
  </div>
</template>

<script>
import SideBar from '@/components/SideBar.vue'; 
import checkAuthMixin from './mixins/checkauth-mixin';

export default {
  name: 'App',
  components: {
    SideBar
  },
  mixins: [checkAuthMixin],
  data() {
    return {
      ready: false,
      firebaseApps: {}
    }
  },
  mounted() {
    this.checkAuth();
  }
}
</script>

<style lang="scss">
  .loading {
    font-size: 40px;
    color: #05ae9e;
  }
  body {
    &.page-login {
      aside.main-sidebar {
        display: none;
      }
      .main-header {
        display: none;
      }
      .content-wrapper {
        margin-left: 0 !important;
      }
    }
  }
  .content-wrapper {
    min-height: calc(100vh - 40px) !important;
  }
  .modal.show {
    display: block;
    background: rgba(0,0,0,.5);
  }
  body:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(.layout-top-nav) .content-wrapper, body:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(.layout-top-nav) .main-footer, body:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(.layout-top-nav) .main-header {
    padding-left: 10px;
  }
</style>