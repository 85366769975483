<template>
    <div class="card border-success">
        <div class="card-header bg-success">
            Charges Breakdown <i class="fa-solid fa-credit-card"></i>
        </div>
            <table class="table table-striped table-responsive-md">
                <thead>
                    <tr>
                        <th scope="col">Company</th>
                        <th scope="col">Description</th>
                        <th scope="col" class="text-right">Amount</th>
                    </tr>
                </thead>
                <tbody v-for="(item, index) in data?.companyCharges" :key="index">
                    <template v-for="(itemAmount, index) in item.items">
                        <tr v-if="itemAmount" :key="'1tr-'+index">
                            <td>{{ item.company?.displayName }}</td>
                            <td>{{itemAmount.description}}</td>
                            <td class="text-right">
                                {{ formatPrice(itemAmount.amount) }}
                            </td>
                        </tr>
                        <tr v-for="(tax, index) in itemAmount.taxes" :key="index" class="mt-3">
                            <td></td>
                            <td>{{ tax.displayName }}</td>
                            <td class="text-right">
                                {{ formatPrice(tax.amount) }}
                            </td>
                        </tr>
                    </template>
                </tbody>
                <tfoot>
                    <tr>
                        <th scope="col">Total</th>
                        <th scope="col"></th>
                        <th scope="col" class="text-right">
                            {{ data?.companyCharges ? formatPrice(sumCharges(data?.companyCharges)) : "-" }}
                        </th>
                    </tr>
                </tfoot>
            </table>
    </div>
</template>

<script>
    import mixin from '../mixins/mixin';
    export default {
        name: "companyCharges",
        props: ['data'],
        mixins: [mixin]
    }
</script>

<style>

</style>