<template>
    <div v-if="Object.keys(user).length" class="card border-primary">
      <div class="card-header bg-primary">
          User
          <i class="fa-solid fa-user"></i>
      </div>
      <table class="table">
        <tbody>
          <tr>
            <th>ID</th>
            <td>
              <object-name :name="userName()" />
            </td>
          </tr>
        </tbody>
      </table>

      <table class="table">
        <thead>
          <th>ID</th>
          <th>Record</th>
          <th>Value</th>
          <th>Created</th>
          <th>Validation</th>
        </thead>
        <tr v-for="key in Object.keys(RECORD_NAMES).filter(key => key in user)" :key="key">
          <td>
            <object-name :name="user[key].name" blank="true" />
          </td>
          <td>
            {{ RECORD_NAMES[key] }}
          </td>
          <td>
            {{ user[key][key] }}
          </td>
          <td>
            {{ customizeDate(user[key].createTime) }}
          </td>
          <td>
            <span class="badge" :class="VALIDATION_STATES[user[key].validation.state].class">
              {{ VALIDATION_STATES[user[key].validation.state].name }}
            </span>
          </td>
        </tr>
      </table>
    </div>
</template>

<script>
  import mixin from '../../../mixins/mixin';
  import objectName from './ObjectName.vue';
  export default {
    components: { objectName },
    name: "userRecords",
    props: ['user'],
    mixins: [mixin],
    methods: {
      userName() {
        let values = Object.values(this.user);
        return values.length ? values[0].name.split("/").slice(0, 4).join("/") : "";
      }
    },
    data() {
      return {
        RECORD_NAMES: {
          names: "Name",
          surnames: "Surname",
          email: "Email",
          phoneNumber: "Phone",
        },
        VALIDATION_STATES: {
          VALIDATION_STATE_UNSPECIFIED: {name: "Unspecified", class: "bg-light"},
          NOT_VALIDATED: {name: "Not validated", class: "bg-secondary"},
          VALIDATING: {name: "Validating", class: "bg-primary"},
          VALIDATED: {name: "Validated", class: "bg-success"},
          FAILED: {name: "Failed", class: "bg-danger"},
        },
      };
    },
  }
</script>

<style>
  table.table {
    margin-bottom: 0
  }
  .card {
    border-width: 1px;
  }
</style>