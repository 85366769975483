import { createWebHistory, createRouter } from 'vue-router';
import Login from './views/UserLogin';
import RecoverPassword from './views/RecoverPassword';
import JourneyList from './views/JourneyList';
import MicroMobility from './views/MicroMobility';
import RideHailing from './views/RideHailing';
import ParkingList from './views/ParkingList';
import HomeView from './views/HomeView';
import store from './store';
import config from './config';

function checkSection(to, from, next) {
  const domain = localStorage.getItem("iomob-domain");
  if(domain && config.customers[domain].sections.indexOf(to.meta.section) < 0) {
    next({
      path: "/"
    });    
  } else {
    next();
  }
}

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',      
      component: HomeView
    },
    {
      path: '/login',
      component: Login,
      name: 'login',
      meta: { title: 'Login Iomob Dashboard' }
    },
    {
      path: '/recover-password',
      component: RecoverPassword,
      name: 'recover-password',
      meta: { title: 'Recover Password Iomob Dashboard' }
    },
    {
      path: '/journeys',
      component: JourneyList,
      children: [
        {
          path: '',
          name: 'journeys',
          component: JourneyList,
          beforeEnter: checkSection,
          meta: { title: 'Journeys Iomob Dashboard', section: 'journeys' }
        },
        {
          path: ':start/:end/:type',
          name: 'journeys-range',
          component: JourneyList,
          beforeEnter: checkSection,
          meta: { title: 'Journeys Iomob Dashboard', section: 'journeys' }
        },
      ],
      beforeEnter: checkSection,
      meta: { title: 'Journeys Iomob Dashboard', section: 'journeys' }
    },
    {
      path: '/micromobility',
      component: MicroMobility,
      children: [
        {
          path: '',
          name: 'micromobility',
          component: MicroMobility,
          beforeEnter: checkSection,
          meta: { title: 'Micromobility Iomob Dashboard', section: 'micromobility' }
        },
        {
          path: ':start/:end/:type',
          name: 'micromobility-range',
          component: MicroMobility,
          beforeEnter: checkSection,
          meta: { title: 'Micromobility Iomob Dashboard', section: 'micromobility' }
        },
      ],
    },
    {
      path: '/ridehailing',
      component: RideHailing,
      children: [
        {     
          path: '',
          name: 'ridehailing',
          component: RideHailing,
          beforeEnter: checkSection,
          meta: { title: 'Ridehailing Iomob Dashboard', section: 'ridehailing' }
        },
        {     
          path: ':start/:end/:type',
          name: 'ridehailing-range',
          component: RideHailing,
          beforeEnter: checkSection,
          meta: { title: 'Ridehailing Iomob Dashboard', section: 'ridehailing' }
        }
      ],
      beforeEnter: checkSection,
      meta: { title: 'Ridehailing Iomob Dashboard', section: 'ridehailing' }
    },
    {
      path: '/parking',
      component: ParkingList,
      children: [
        {
          path: '',
          name: 'parking',
          component: ParkingList,
          beforeEnter: checkSection,
          meta: { title: 'Parking Iomob Dashboard', section: 'parking' }
        },
        {
          path: '/parking/:start/:end/:type',
          name: 'parking-range',
          component: ParkingList,
          beforeEnter: checkSection,
          meta: { title: 'Parking Iomob Dashboard', section: 'parking' }
        },
      ],
      beforeEnter: checkSection,
      meta: { title: 'Parking Iomob Dashboard', section: 'parking' }
    },
    
    {
      path: '/:pathMatch(.*)*',
      redirect: '/',
    },
  ]
});

router.beforeEach((to) => {
  document.title = to.meta.title || "Iomob Dashboard";  
});

export default router;
