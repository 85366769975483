<template>
  <div class="modal show">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" :class="{ 'modal-xl': size == 'xl', 'modal-fullscreen': size == 'fullscreen' }">
        <div class="modal-content">          
          <div class="modal-header">
            <h2>{{ title }}</h2>
            <img
              v-if="!hideClose"
              src="@/assets/close.svg"
              class="pointer"
              @click="close()"
            />
          </div>
          <div class="modal-body">
            <slot name="body"></slot>
          </div>
          <div class="modal-footer text-center" v-if="!!$slots.footer">
            <slot name="footer"></slot>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['title', 'size', 'hideClose'],
  methods: {
    close() {
      this.$emit('close');
    }
  }
}
</script>

<style>

</style>