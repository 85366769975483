<template>
    <div class="card border-danger">
        <div class="card-header bg-danger">
            Booking
            <i class="fa-solid fa-ticket"></i>
        </div>
        <table class="table">
          <tbody>
            <tr>
              <th>ID</th>
              <td>
                <object-name :name="booking.name" />
              </td>
            </tr>
            <tr>
              <th>Booked date</th>
              <td>
                <span class="d-inline-block text-truncate" style="max-width: 200px;">
                  {{ customizeDate(booking.createTime) }}
                </span>
              </td>
            </tr>
            <tr>
              <th>Status</th>
              <td>
                <span :class="getStatusClass(booking.status)">
                  {{ getStatusLabel(booking.status) }}
                </span>
              </td>
            </tr>
            <tr v-if="!showChargesBreakdown">
              <th>Price</th>
              <td>
                {{ formatPrice(booking.price) }}
              </td>
            </tr>
            <tr>
              <th>Arrival</th>
              <td>{{ customizeDate(booking.startTime) }}</td>
            </tr>
            <tr>
              <th>Exit</th>
              <td>{{ customizeDate(booking.endTime) }}</td>
            </tr>

            <tr v-if="booking.status == 'ACTIVE'">
              <th>Current duration</th>
              <td>
                {{ differenceBetweenDate(booking.startTime, booking.endTime) }}
              </td>
            </tr>
            <tr v-else>
              <th>Duration</th>
              <td>
                {{ differenceBetweenDate(booking.startTime, booking.endTime) }}
              </td>
            </tr>

            <tr v-if="booking.vehicle.driver.name || booking.vehicle.driver.surname">
              <th>Driver</th>
              <td>
                {{ booking.vehicle.driver.name }}
                {{ booking.vehicle.driver.surname }}
              </td>
            </tr>
            <tr>
              <th>Car registration</th>
              <td>
                {{ booking.vehicle?.plate }}
              </td>
            </tr>
            <tr v-if="booking.vehicle?.driver?.phone">
              <th>Contact</th>
              <td>
                {{ booking.vehicle.driver.phone }}
              </td>
            </tr>
            <tr v-if="canCancel(booking.status)">
              <th></th>
              <td>
                <button
                  class="btn cancel-booking btn-danger"
                  @click="cancelBooking()"
                >
                  Cancel booking
                </button>
              </td>
            </tr>
          </tbody>
        </table>
    </div>
</template>

<script>
  import mixin from '../../../mixins/mixin';
  import objectName from './ObjectName.vue';
  export default {
    components: { objectName },
    name: "parkingBookingCard",
    props: ['booking'],
    mixins: [mixin],
    data() {
      return {
      };
    },
    methods: {
      cancelBooking() {
        this.$emit("cancel-booking");
      },
      canCancel(data) {
        return !["COMPLETED", "CANCELED_BY_PASSENGER", "DELETED"].includes(
          data
        );
      },
    }
  }
</script>

<style>
  table.table {
    margin-bottom: 0;
  }
  .card {
    border-width: 1px;
  }
</style>