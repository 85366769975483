import { getAuth } from "firebase/auth";
import * as firebase from "firebase/app";
import config from '../config';

export default {
  methods: {
    checkAuth(redirDefault) {
      /*
        if we don't have the user domain, then redirect to login
      */
      if(! localStorage.getItem('iomob-domain')) {
        // console.log("No domain")
        this.ready = true;
        this.$router.push("/login");
        return;
      }
      
      config.firebase.forEach(conf => {
          this.firebaseApps[conf.domain] = {
              app: firebase.initializeApp(conf.configuration, conf.name),
              conf: conf
          }
      });
      const auth = getAuth(this.firebaseApps[localStorage.getItem('iomob-domain')].app);
      auth.onAuthStateChanged(user => {
        if (user) {
          this.ready = true;
          const domain = user.email.split('@')[1];
          this.$store.commit('setUser', {
              token: user.accessToken,
              domain,
              email: user.email
          });
          localStorage.setItem('iomob-domain', domain);
          /*
            refresh Firebase token every 60 seconds
          */
          setInterval( () => {           
            auth.currentUser?.getIdToken(true).then(idToken => {
            });
          }, 1000 * 60);

          if(redirDefault) {
            this.$router.push(redirDefault);
          }
        } else {
          this.ready = true;
          // console.log("not logged");
        }
      });
    }
  }
}