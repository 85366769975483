<template>
    <button type="button" class="btn btn-default btn-sm mr-1" @click="save()">
        <i class="fa-solid fa-cloud-arrow-down"></i> JSON
    </button>
</template>

<script>

import downloadMixin from "../mixins/download-mixin";

export default {
    name: "DownloadJson",
    props: ['data', 'filename'],
    mixins: [downloadMixin],
    methods: {
        save() {
            this.downloadBlob(this.filename, JSON.stringify(this.data),  "text/json");
        }
    }
}
</script>

<style scoped>
button {
    white-space: nowrap;
}
</style>