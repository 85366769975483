<template>
    <div class="wrapper" :class="`wrapper wrapper-${styleClass}`" >
      <table-header ref="tableHeader" 
        :columns="columns" 
        :onSort="onSort" 
        :initialSortBy="defaultSortField"
        />
      <DynamicScroller
        :items="items"
        :min-item-size="40"
        class="scroller"   
        :class="`scroller scroller-${styleClass}`"            
        key-field="name"
      >
        <template v-slot="{ item, index, active }">
          
          <DynamicScrollerItem
            :item="item"
            :data-index="index"     
            class="dynamic-item-wrapper"
            :class="{ 'alt-row': index % 2 == 0 }"
            :active="active"
          >  
            <slot :item="item"></slot>
          </DynamicScrollerItem>
        </template>
      </DynamicScroller>
    </div>
</template>

<script>
import { DynamicScroller, DynamicScrollerItem } from 'vue-virtual-scroller';
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";
import TableHeader from '../components/TableHeader.vue';

import mixin from '../mixins/mixin';

export default {
  mixins: [mixin],
  components: {
    DynamicScroller, 
    DynamicScrollerItem,
    TableHeader
  },
  props: ['columns', 'items', 'defaultSortField', 'styleClass'],
  methods: {
    onSort(options) {
      this.$emit('sort', options);
    }
  }
}
</script>

<style scoped>
  .scroller-journey {
    height: calc(100vh - 224px) !important;
  }
  .wrapper .wrapper-journey {
    height: calc(100vh - 175px);
  }
  .wrapper {
    overflow: hidden; 
    height: calc(100vh - 127px);
    min-width: 1100px;
  }

  .alt-row {
    background-color: #e5e5e5;
  }

  .dynamic-item-wrapper {
    padding: 10px;
  }
</style>