<template>
  <span :class="'badge badge-' + statusClass[statusName[status]]">
    {{ statusName[status] }}
    &nbsp;
    <i :class="'fa-solid fa-' + statusIcon[statusName[status]]"></i>
  </span>
</template>

<script>
  import mixin from '../../../mixins/mixin';

  const FAILED = "Failed";
  const UNCAPTURED = "Uncaptured";
  const SUCCEEDED = "Succeeded";
  const CANCELED = "Canceled";

  export default {
    name: "stripeStatusBadge",
    props: ['status'],
    mixins: [mixin],
    data() {
      return {
        statusClass: {
          [UNCAPTURED]: "secondary",
          [CANCELED]: "secondary",
          [SUCCEEDED]: "success",
          [FAILED]: "danger",
        },
        statusIcon: {
          [UNCAPTURED]: "clock",
          [CANCELED]: "rotate-left",
          [SUCCEEDED]: "check",
          [FAILED]: "xmark",
        },
        statusName: {
          requires_payment_method: FAILED,
          requires_confirmation: UNCAPTURED,
          requires_action: UNCAPTURED,
          processing: UNCAPTURED,
          requires_capture: UNCAPTURED,
          canceled: CANCELED,
          succeeded: SUCCEEDED,
        }
      };
    },
    methods: {
    }
  }
</script>

<style>
</style>