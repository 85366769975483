<template>
    <div class="align-items-center justify-content-center">
        <loading v-model="isLoading" :active="isLoading"
            :is-full-page="false"
            color="#fff"
            background-color="#000" />
        <div class="login-box">
            <div class="text-center mb-4">
                <img src="@/assets/logo.svg" alt="Logo Iomob" />
            </div>
            <div class="card mb-4">
                <div class="card-body login-card-body">
                    <p class="login-text">Sign in to start your session</p>
                    <div class="mb-3">
                        <input type="email" class="form-control" placeholder="Email" autocomplete="off" v-model="email">
                    </div>
                    <div class="mb-3">
                        <input type="password" class="form-control" placeholder="Password" autocomplete="off" v-model="pw">
                    </div>
                    <p v-if="errorMessage" class="alert alert-warning">{{ errorMessage }}</p>
                    <div class="d-flex justify-content-end">
                        <button class="btn btn-primary" @click="signIn()">Sign In</button>
                    </div>
                </div>
            </div>
            <p>
                <a href="/recover-password">
                    Forgot password?
                </a>
            </p>
        </div>
    </div>
</template>

<script>
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { api } from '../services';
import Loading from 'vue-loading-overlay';
import config from '../config';
import 'vue-loading-overlay/dist/vue-loading.css';
import * as firebase from "firebase/app";

export default {
    name: "UserLogin",
    components: {
        Loading
    },
    data() {
        return {
            isLoading: false,
            email: "",
            pw: "",
            errorMessage: null,
            firebaseApps: {}
        }
    },
    created() {
        config.firebase.forEach(conf => {
            this.firebaseApps[conf.domain] = {
                app: firebase.initializeApp(conf.configuration, conf.name),
                conf: conf
            }
        });
    },
    methods: {
        signIn() {
            this.errorMessage == null;
            if(!(this.email && this.pw && this.email.indexOf('@') > 0)) {
                this.errorMessage = "Enter email and password";
                return;
            }

            const domain = this.email.split('@')[1].toLowerCase();
            if(! this.firebaseApps[domain]) {
                this.errorMessage = "Email not allowed";
                return;
            }

            this.isLoading = true;

            const auth = getAuth(this.firebaseApps[domain].app);
            signInWithEmailAndPassword(auth, this.email, this.pw)
            .then((response) => {
                // console.log(`UserLogin: signInWithEmailAndPassword callback, domain: ${domain}`);
                setTimeout( () => {
                    this.$store.commit('setUser', {
                        token: response.user.accessToken,
                        domain: this.email.split('@')[1],
                        email: this.email
                    });
                    localStorage.setItem('iomob-domain', domain);
                    /*
                        refresh Firebase token every 60 seconds
                    */
                    setInterval( () => {    
                            if(auth?.currentUser?.getIdToken) {
                                auth.currentUser.getIdToken(true).then(idToken => {});
                            }
                    }, 1000 * 60)
                    this.$router.push(`/${config.customers[domain].sections[0]}`);
                }, 500);                
            })
            .catch(error => {
                if(error.code == "auth/user-not-found" ||
                    error.code == "auth/wrong-password") {
                    this.errorMessage = "Invalid email or password";
                } else if(error.code == "auth/invalid-email") {
                    this.errorMessage = "Invalid email";
                } else if(error.code == "auth/user-disabled") {
                    this.errorMessage = "User disabled";
                } else {
                    this.errorMessage = error.message;
            }
            });
            this.isLoading = false;
        },
    },
    mounted () {
        document.body.classList.add('page-login');
    },
    unmounted () {
        document.body.classList.remove('page-login');
    },
}
</script>

<style lang="scss" scoped>
@import "../css/colors";
    .login-text {
        color: $background;
        font-size: 18px;
    }
    .login-box {
        color: $background !important;
        margin: auto;
    }
</style>
