export default {
    debug: process.env.VUE_APP_DEBUG || false,
    firebase: [
        {
            name: "iomob",
            domain: "iomob.net",
            configuration: {
                apiKey: "AIzaSyBC0b7ujxkXfk_FzBDoqgaz1DPj7q60yIg",
                authDomain: "iomob-dashboard.firebaseapp.com",
                projectId: "iomob-dashboard",
                storageBucket: "iomob-dashboard.appspot.com",
                messagingSenderId: "95378758389",
                appId: "1:95378758389:web:865feb234a69e26f7ac3bc",
                measurementId: "G-X2JCNKVVKR",
            },
        },
        {
            name: "lner",
            domain: "lner.co.uk",
            configuration: {
                apiKey: "AIzaSyCh1IbDGI6-RClaLVTFuklwKr3E57d9KZ8",
                authDomain: "dashboard-lner.firebaseapp.com",
                projectId: "dashboard-lner",
                storageBucket: "dashboard-lner.appspot.com",
                messagingSenderId: "966707809642",
                appId: "1:966707809642:web:ca67042ae86f6df76c6913",
                measurementId: "G-FTGCBH0DP2",
            },
        },
        {
            name: "brightbike",
            domain: "gobrightbike.com",
            configuration: {
                apiKey: "AIzaSyCIesXXmyFSjzC9z7N7Epdlr_Bwa45Np30",
                authDomain: "dashboard-brightbike.firebaseapp.com",
                projectId: "dashboard-brightbike",
                storageBucket: "dashboard-brightbike.appspot.com",
                messagingSenderId: "484074481875",
                appId: "1:484074481875:web:34ddd35f878723a7360c26",
                measurementId: "G-3WEFV83MHZ",
            },
        },
        {
            name: "brightline",
            domain: "gobrightline.com",
            configuration: {
                apiKey: "AIzaSyD4zLwoPA6lnfL0g9UOd-uIdJ7JVPJeSDk",
                authDomain: "dashboard-brightline.firebaseapp.com",
                projectId: "dashboard-brightline",
                storageBucket: "dashboard-brightline.appspot.com",
                messagingSenderId: "704019228823",
                appId: "1:704019228823:web:f5131376f2afa6d42a61d2",
                measurementId: "G-BYGK6Z7B6L",
            },
        },
        {
            name: "brightline",
            domain: "bltrain.com",
            configuration: {
                apiKey: "AIzaSyD4zLwoPA6lnfL0g9UOd-uIdJ7JVPJeSDk",
                authDomain: "dashboard-brightline.firebaseapp.com",
                projectId: "dashboard-brightline",
                storageBucket: "dashboard-brightline.appspot.com",
                messagingSenderId: "704019228823",
                appId: "1:704019228823:web:f5131376f2afa6d42a61d2",
                measurementId: "G-BYGK6Z7B6L",
            },
        }
    ],
    vehicles: {
        'bus': { name: "Bus", icon: 'fas fa-bus', color: '#2e8aff', background: 'transparent' },
        'train': { name: "Train", icon: 'fas fa-train', color: '#ff3d3d', background: 'transparent' },
        'subway': { name: "Subway", icon: 'fas fa-subway', color: '#ff3d3d', background: 'transparent' },
        'ferry': { name: "Ferry", icon: 'fas fa-ship', color: '#00bfff', background: 'transparent' },
        'taxi': { name: "Taxi", icon: 'fas fa-taxi', color: '#ff8c00', background: 'transparent' },
        'bike': { name: "Bike", icon: 'fas fa-bicycle', color: '#ff00ff', background: 'transparent' },
        'motorcycle': { name: "Motorcycle", icon: 'fas fa-motorcycle', color: '#ffff00', background: '#333' },
        'scooter': { name: "Scooter", icon: 'fac fa-scooter', color: '#4fdb4f', background: 'transparent' },
        'parking': { name: "Parking", icon: 'fas fa-parking', color: '#00bfff', background: 'transparent' },
        'walk': { name: "Walk", icon: 'fas fa-walking', color: '#b5b5b5', background: 'transparent' },
    },
    customers: {
        "iomob.net": {
            sections: ['journeys', 'micromobility', 'ridehailing', 'parking'],
            customerColumn: true,
            showChargesBreakdown: true,
            dateFormat: "DD/MM/YYYY HH:mm",
        },
        "gobrightbike.com": {
            logo: "brightbike.svg",
            sections: ['micromobility'],
            customerColumn: false,
            showChargesBreakdown: false,
            dateFormat: "MM/DD/YYYY HH:mm",
        },
        "bltrain.com": {
            logo: "brightline.svg",
            sections: ['ridehailing', 'journeys', 'micromobility', 'parking'],
            customerColumn: false,
            showChargesBreakdown: false,
            dateFormat: "MM/DD/YYYY HH:mm",
        },
        "gobrightline.com": {
            logo: "brightline.svg",
            sections: ['ridehailing', 'journeys', 'micromobility', 'parking'],
            customerColumn: false,
            showChargesBreakdown: false,
            dateFormat: "MM/DD/YYYY HH:mm",
        },
        "lner.co.uk": {
            logo: "lner.png",
            sections: ['ridehailing', 'journeys', 'micromobility', 'parking'],
            customerColumn: false,
            showChargesBreakdown: false,
            dateFormat: "DD/MM/YYYY HH:mm",
        }
    }
}
