<template>
    <div>
        <div class="detail-view">
            <strong>Started at:</strong>
            {{ startTime }}, {{ startFrom }}
            <div v-for="(item, index) in routesList" :key="index" class="mt-3 mb-4">
                <ul class="list-group mb-2" v-for="(leg, i) in item.legs" :key="i">
                    <li v-if="leg.detail" class="list-group-item">
                        <strong>Type:</strong> {{ getLegType(leg) }}
                    </li>
                    <li v-if="leg.distanceM" class="list-group-item">
                        <strong>Distance:</strong> {{ leg.distanceM }}m
                    </li>
                    <li v-if="leg.duration" class="list-group-item">
                        <strong>Duration:</strong> {{ leg.duration }}
                    </li>
                    <li v-if="leg.startsAt" class="list-group-item">
                        <strong>Starts at:</strong>
                        {{ customizeTime(leg.startsAt) }}
                    </li>
                    <li class="list-group-item" v-if="leg.detail && hasBooking(leg)">
                        <router-link class="btn btn-primary btn-xs" :to="getBookingLink(leg)">View booking</router-link>
                    </li>
                </ul>
             </div>
            <strong>Ended at:</strong>
            {{ endTime }}, {{ endTo }}
        </div>
    </div>
</template>

<script>
import config from "../config";
import mixin from '../mixins/mixin';
import moment from 'moment';

export default {
    name: "legsList",
    props: ['journey'],
    mixins: [mixin],
    data() {
        return {
            routesList: [],
            vehicles: config.vehicles,
            startTime: "",
            endTime: "",
            startFrom: "",
            endTo: "",
        }
    },
    created() {
        if(this.journey.inbound) {
            this.routesList.push(this.journey.inbound);
        }
        if(this.journey.outbound) {
            this.routesList.push(this.journey.outbound);
        }
        if(this.journey.inboundRoutes) {
            this.journey.inboundRoutes.forEach(item => {
                if(item.duration) {
                    this.routesList.push(item);
                }
            })
        }
        if(this.journey.outboundRoutes) {
            this.journey.outboundRoutes.forEach(item => {
                if(item.duration) {
                    this.routesList.push(item);
                }
            })
        }
        this.startTime = moment(this.routesList[0].startTime).format("DD/MM/YYYY");
        if(this.routesList[0].userSpecifiedOrigin) {
            this.startFrom = this.routesList[0].userSpecifiedOrigin;
        }
        this.endTime = moment(this.routesList[this.routesList.length - 1].endTime).format("DD/MM/YYYY");
        if(this.routesList[0].userSpecifiedDestination) {
            this.endTo = this.routesList[0].userSpecifiedDestination;
        }
    },
    methods: {
        customizeTime(date) {
            return moment(date).format("HH:mm");
        },
        getBookingLink(leg) {
            if(leg.detail?.ridehailing?.ridehailingBooking?.bookingName) {
                const date = moment(leg.startsAt)
                return `/ridehailing/${date.format('YYYY-MM-DD')}/${date.format('YYYY-MM-DD')}/book?name=${leg.detail.ridehailing.ridehailingBooking.bookingName}`;
            }
            return '#';
        },
        hasBooking(leg) {
            return leg.detail?.ridehailing?.ridehailingBooking?.bookingName;
        },
        getLegType(leg) {
            let vehicle = Object.keys(leg.detail)[0];
            if (vehicle == "transit") {
                return "Transit";
            }
            if (vehicle == "ridehailing") {
                return "taxi";
            }
            if (vehicle == "privateMotorVehicle") {
                return "motorcycle";
            }
            if(config.vehicles[vehicle] && config.vehicles[vehicle].name) {
                return config.vehicles[vehicle].name;
            }
            return vehicle;
        },
    }
}
</script>

<style scoped>
.detail-view {
  height: 562px;
  overflow-y: scroll;
} 
.leg {
  border-radius: 4px;
  border: 1px solid #f0f0f0;
  padding: 6px 10px;
  margin-bottom: 10px;
  box-shadow: rgba(0, 0, 0, .05) 0 0px 10px;
}
</style>
<style>
.detail-view .list-group-item {
  padding: 6px 10px;
}
</style>