<template>
  <div>
    <loading
      v-model="isLoading"
      :active="isLoading"
      :is-full-page="false"
      color="#fff"
      background-color="#000"
    />
    <div class="content">
      <div class="container-fluid pt-2">
        <div class="card">
          <div class="card-header bg-info align-middle ">
            <h1 class="card-title">Parking</h1>
            <div class="card-tools">
              <div class="d-flex justify-content-end align-items-center">
                <date-range
                  @changed="filterByDateRange"
                  :start-date="dateRange.start"
                  :end-date="dateRange.end"
                  :type="dateRange.type"
                />
                <i></i>
                <input
                  type="search"
                  @keyup="applySearch"
                  class="form-control"
                  v-model="searchText"
                  placeholder="Search..."
                />
                <i></i>
                <download-json :data="rowsFiltered" filename="parkings.json" />
                <download-csv @click="saveCSV()" />
              </div>
            </div>
          </div>
          <div class="card-body">
            <fast-table :items="rowsFiltered" :columns="tableCols" v-slot="props" @sort="onSort" :defaultSortField="defaultSortField" styleClass="parking">
              <div class="dynamic-item" @click="onRowClick(props.item)">
                <div class="td text-truncate" :style="`width: ${getColumnWidth('createTime')}`">{{ customizeDate(props.item.createTime) }}</div>
                <div class="td text-truncate" v-if="hasCustomerColumn" :style="`width: ${getColumnWidth('client')}`">
                    {{ getCustomerName(props.item) }}
                  </div>
                <div class="td text-truncate" :style="`width: ${getColumnWidth('fullName')}`">
                  <span class="mr-1"
                    :class="notValidatedClass(props.item.user?.names)"
                  >{{ props.item.user?.names?.names }}</span>
                  <span :class="notValidatedClass(props.item.user?.surnames)">{{
                    props.item.user?.surnames?.surnames
                  }}</span>
                </div>
                <div class="td text-truncate" :style="`width: ${getColumnWidth('email')}`">
                  <span :class="notValidatedClass(props.item.user.email)">{{
                    props.item.user.email?.email
                  }}</span>
                </div>
                <div class="td" :style="`width: ${getColumnWidth('company')}`">
                  <img
                    v-if="props.item?.parking?.company?.visualAssets?.squareLogo?.urlSvg"
                    :src="props.item?.parking?.company.visualAssets.squareLogo.urlSvg"
                  />
                  &nbsp;
                  <span>{{ props.item.parking.company?.displayName }}</span>
                </div>
                <div class="td" :style="`width: ${getColumnWidth('status')}`">
                  <span :class="getStatusClass(props.item.status)">{{
                    getStatusLabel(props.item.status)
                  }}</span>
                </div>
                <div class="td" :style="`width: ${getColumnWidth('price')}`">
                  {{ formatPrice(props.item.price) }}
                </div>
                <div class="td" :style="`width: ${getColumnWidth('startTime')}`">
                  {{ customizeDate(props.item.startTime) }}
                </div>
                <div class="td text-truncate" :style="`width: ${getColumnWidth('duration')}`">
                  <span v-if="!isStatusCanceled(props.item.status)">
                    {{ differenceBetweenDate(props.item.startTime, props.item.endTime) }}
                  </span>
                  <span v-else>
                    -
                  </span>
                </div>

              </div>
            </fast-table>
          </div>
        </div>
      </div>
    </div>

   <modal-popup v-if="showModal" size="fullscreen" title="Parking booking" @close="closeModal()">
     <template #body>
       <loading
          v-model="isCancelLoading"
          :active="isCancelLoading"
          :is-full-page="false"
          color="#fff"
          background-color="#000"
        />
       <div class="modal-body">
          <div class="row">
            <div class="col">
              <parking-panel :item="parkingSelected" @cancel-booking="cancelBooking(1)" />
            </div>
          </div>
        </div>
     </template>
   </modal-popup>

   <modal-popup v-if="showFirstCancelBookingModal" title="Cancel booking" :hide-close="true">
     <template #body>
       <div class="text-center mb-4">
          <span>Are you sure you want to cancel this booking?</span>
        </div>
        <div class="card mb-3 mx-5">
          <ul class="list-group list-group-flush">
            <li class="list-group-item">
              <img
              v-if="parkingSelected.parking?.company?.visualAssets?.squareLogo?.urlSvg"
              :src="parkingSelected.parking?.company.visualAssets.squareLogo.urlSvg"
              />
              <span class="ml-2">
                Parking Garage
              </span>
              <span style="float:right">{{ price(parkingSelected) }}</span>
            </li>
            <li class="list-group-item">
              Cancellation fee
              <strong style="float:right">
                {{ formatPrice(sumCharges(cancellingItem.charge.companyCharges)) }}
              </strong>
            </li>
          </ul>
        </div>
        <div class="text-center mt-4">
          Please, confirm this is the right booking to be cancelled.
        </div>
     </template>
     <template #footer>
       <button class="btn cancel-booking small btn-danger" @click="cancelBooking(2)">
          Yes, cancel it
        </button>
        <button class="btn cancel-booking reverse btn-secondary" @click="showFirstCancelBookingModal = false">
          Not yet
        </button>
     </template>
   </modal-popup>

   <modal-popup v-if="showSecondCancelBookingModal" title="Cancel booking" :hide-close="true">
     <template #body>
       <loading
          v-model="isCancelLoading"
          :active="isCancelLoading"
          :is-full-page="false"
          color="#fff"
          background-color="#000"
        />
       <div class="text-center mb-4">
          Make sure you are cancelling the right booking.
        </div>
        <div class="card mb-3 mx-5">
          <ul class="list-group list-group-flush">
            <li class="list-group-item">
              <img
              v-if="parkingSelected.parking?.company?.visualAssets?.squareLogo?.urlSvg"
              :src="parkingSelected.parking?.company.visualAssets.squareLogo.urlSvg"
              />
              <span class="ml-2">
                Parking Garage
              </span>
              <span style="float:right">{{ price(parkingSelected) }}</span>
            </li>
            <li class="list-group-item">
              Cancellation fee
              <strong style="float:right">
                {{ formatPrice(sumCharges(cancellingItem.charge.companyCharges)) }}
              </strong>
            </li>
          </ul>
        </div>
        <div class="text-center mt-4">
          If you are not sure, please confirm the booking details before cancelling it.
        </div>
     </template>
     <template #footer>
       <button class="btn cancel-booking btn-danger" @click="deleteBooking()">
          Cancel booking
        </button>
        <button class="btn cancel-booking btn-secondary" @click="showModals()">
          View booking details
        </button>
     </template>
   </modal-popup>
  </div>
</template>

<script>
import api from "../services/api";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import moment from "moment";
import mixin from "../mixins/mixin";
import sortMixin from "../mixins/sort-mixin";
import downloadMixin from "../mixins/download-mixin";
import DateRange from "../components/DateRange.vue";
import DownloadJson from "../components/DownloadJson.vue";
import DownloadCsv from '../components/DownloadCsv.vue';
import ModalPopup from '../components/ModalPopup.vue';
import ParkingPanel from '../components/panels/ParkingPanel.vue';

import FastTable from '../components/FastTable.vue';

export default {
  name: "ParkingList",
  components: {
    Loading,
    DateRange,
    DownloadJson,
    DownloadCsv,
    ModalPopup,
    ParkingPanel,
    FastTable
  },
  mixins: [mixin, sortMixin, downloadMixin],
  data() {
    return {
      rows: [],
      rowsFiltered: [],
      parking: [],
      parkingSelected: {},
      showFirstCancelBookingModal: false,
      showSecondCancelBookingModal: false,
      currencies: {
        EUR: "€",
        USD: "$",
        GBP: "£",
      },
      period: "",
      searchText: "",
      dateType: 'book',
      defaultSortField: 'createTime',
      tableCols: [
        { text: "Booked date", direction: "desc", field: "createTime", sortable: true, width: 13 },
        { text: "Full name", field: "fullName", sortable: true, width: 15 },
        { text: "Email", field: "email", sortable: true, width: 17 },
        { text: "Provider", field: "company", sortable: true, width: 16 },
        { text: "Status", field: "status", sortable: true, width: 10 },
        { text: "Price", field: "price", sortable: true, width: 7 },
        { text: "Start time", field: "startTime", sortable: true, width: 13 },
        { text: "Duration", field: "duration", sortable: true, width: 9 }
      ],
    };
  },
  created() {
    this.dateRange = { ...this.$store.state.dateRange};
    this.initFilters();
    this.manageCustomerColumn();
  },
  methods: {
    showModalFromLink() {
      if(this.$route.query?.name) {
        const row = this.rowsFiltered.find(item => {
          if(item.name == this.$route.query.name) {
            return item;
          }
        })
        this.onRowClick(row);
      }
    },
    async initFilters() {
      if(! this.$route.params.start) {
        this.$router.push(`/parking/${moment(this.dateRange.start).format("YYYY-MM-DD")}/${moment(this.dateRange.end).format("YYYY-MM-DD")}/${this.dateRange.type}`)
      } else {
        await this.loadData();
        this.showModalFromLink();
      }
    },
    onSort(params) {
      if(params.direction == null) {
        this.rowsFiltered = [ ...this.rows ];
        return;
      }
      if(this.sortingFunctions[params.field]) {
        this.rowsFiltered = this.rowsFiltered.sort(this.sortingFunctions[params.field](params.direction));
      }
    },
    showModals() {
      this.showFirstCancelBookingModal = false;
      this.showSecondCancelBookingModal = false;
      this.showModal = true;
    },
    async cancelBooking(step) {
      if (step == 1) {
        this.isCancelLoading = true;
        try {
          const data = await api().delete(`/api/cancel_parking_booking?dry_run=true&id=${this.parkingSelected.name}`);
          this.cancellingItem = data.data;
          this.isCancelLoading = false;
          this.showModal = false;

          this.showFirstCancelBookingModal = true;
          this.showSecondCancelBookingModal = false;
        } catch(e) {
          console.log(e);
          this.isCancelLoading = true;
          this.$notify({
            type: "error",
            title: "Error",
            text:
              e.response && e.response.data
                ? e.response.data.message
                : e.message,
          });
        }
      }
      if (step == 2) {
        this.showFirstCancelBookingModal = false;
        this.showSecondCancelBookingModal = true;
      }
    },
    async deleteBooking() {
      this.isCancelLoading = true;
      try {
        await api().delete(`/api/cancel_parking_booking?id=${this.parkingSelected.name}`);
        this.showSecondCancelBookingModal = false;
        this.isCancelLoading = false;
        this.$notify({
            type: "success",
            title: "Confirm",
            text: "Booking cancelled"
          });
      } catch(e) {
        this.$notify({
            type: "error",
            title: "Error",
            text:
              e.response && e.response.data
                ? e.response.data.message
                : e.message,
          });
      }
      this.loadData();
    },
    async filterByDateRange(payload) {
      this.dateRange = payload.range;
      this.dateType = payload.type;
      if(this.dateType == "book") {
        this.defaultSortField = "createTime";
      } else {
        this.defaultSortField = "startTime";
      }
      this.$store.commit('setDateRange', { start: this.dateRange.start, end: this.dateRange.end, type: this.dateType });
      this.$router.push(`/parking/${moment(this.dateRange.start).format("YYYY-MM-DD")}/${moment(this.dateRange.end).format("YYYY-MM-DD")}/${this.dateType}`);
      this.loadData();
    },
    applySearch() {
      clearInterval(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.rowsFiltered = [...this.rows];
        if(this.searchText.length > 0) {
          this.rowsFiltered = this.rowsFiltered.filter((item) => {
            return this.stringInObject(this.searchText, item);
          });
        }
      }, 250);
    },
    async loadData() {
      this.isLoading = true;
      try {
        const start = this.dateRange
          ? moment(this.dateRange.start).startOf('day').toISOString(true)
          : "";
        const end = this.dateRange
          ? moment(this.dateRange.end).endOf('day').toISOString(true)
          : "";
        const params = new URLSearchParams({
          start, end, date: this.dateType
        });

        const data = await api().get(`/api/parking?${params.toString()}`);
        this.parkings = data.data.data;
        this.rows = this.parkings;
        this.rows = this.rows.map(item => {
            item.extra = {
                duration: this.differenceBetweenDate(item.startTime, item.endTime),
                price: this.price(item),
                start: this.customizeDate(item.startTime),
                end: this.customizeDate(item.endTime),
                status: this.getStatusLabel(item.status),
                nameSurname: `${item.user?.names?.names.trim()} ${item.user?.surnames?.surnames.trim()}`.trim()
            };
            return item;
        });
        this.rowsFiltered = [ ...this.rows];
        this.onSort({field: "createTime", direction: "desc"});
      } catch (e) {
        if(e.response?.status == 401 || e.response?.status == 403) {
          return;
        }
        console.log(e)
        this.$notify({
          type: "error",
          title: "Error",
          text: e.response?.data?.message || e.message
        });
      }
      this.isLoading = false;
    },
    search() {
      const searchText = this.searchText.trim().toLowerCase();
      if (!searchText) {
        this.rowsFiltered = this.rows;
        return;
      }

      this.rowsFiltered = this.rows.filter((item) => {
        if (
          item.user?.surnames?.surnames.toLowerCase().indexOf(searchText) >= 0
        ) {
          return true;
        }
        if (item.user?.names?.names.toLowerCase().indexOf(searchText) >= 0) {
          return true;
        }
        if (
          item.user?.phoneNumber?.phoneNumber
            .toLowerCase()
            .indexOf(searchText) >= 0
        ) {
          return true;
        }
      });
    },
    differenceBetweenDate(start, end) {
      const startDate = moment(start);
      const endDate = moment(end);
      const difference = endDate.diff(startDate);
      const days = moment.duration(difference).days();
      const hours = moment.duration(difference).hours();
      const minutes = moment.duration(difference).minutes();
      let result = "";
      if (days == 1) {
        result += days + " day ";
      }
      if (days > 1) {
        result += days + " days ";
      }
      if (hours > 0) {
        result += hours + " h ";
      }
      if (minutes >= 1) {
        result += minutes + " min";
      }
      if (result === "") {
        result += "0 min";
      }
      return result;
    },
    onRowClick(params) {
      this.$router.push({ path: this.$route.path, query: { name: params.name } });
      this.parkingSelected = params;
      this.showModal = true;
    },
    filters() {
      this.rowsFiltered = [...this.rows];
      if (this.period) {
        let dateBefore = moment();
        if (this.period == "w") {
          dateBefore = dateBefore.subtract(7, "days");
        }
        if (this.period == "ww") {
          dateBefore = dateBefore.subtract(14, "days");
        }
        this.rowsFiltered = this.rows.filter((item) =>
          moment(item.endTime).isAfter(dateBefore)
        );
      }
    },
    saveCSV() {
      const data = [
        [
          'Booking time',
          'Full name',
          'Email',
          'Phone number',
          'Company',
          'Status',
          'Price',
          'Start time',
          'Duration'
        ],
        ...this.rowsFiltered.map(item => {
          return [
            this.customizeDate(item.createTime),
            `${item.user?.names?.names} ${item.user?.surnames?.surnames}`,
            item.user.email?.email,
            item.user?.phoneNumber?.phoneNumber,
            item.parking?.company?.displayName || "",
            this.getStatusLabel(item.status),
            item.price ? `${this.price(item)}` : "",
            this.customizeDate(item.startTime),
            this.differenceBetweenDate(item.startTime, item.endTime)
          ]
        })
      ];
      this.downloadBlob("ridehailings.csv", this.arrayToCsv(data),  "text/csv");
    }
  },
};
</script>
