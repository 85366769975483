<template>
  <div class="row" :class="{ 'with-panel': showModalPanel }">
    <div class="col">
      <div class="p-1 vehicles-legend">
        <h4 class="mb-3">Vehicles legend</h4>
        <div
          class="p-2"
          v-for="vehicle in vehicles"
          :key="vehicle.name"
        >
          <span
            class="icon mr-2"
            :style="{
              color: vehicle.color,
              background: vehicle.background,
            }"
          >
            <i :class="vehicle.icon"></i>
          </span>
          {{ vehicle.name }}
        </div>
      </div>
    </div>
    <div
      class="col-6"
    >
      <div class="card p-1">
        <div id="map"></div>
      </div>
    </div>
    <div class="col">
      <h4 class="mb-4">Trip details</h4>
      <object-name :name="journey.name" />
      <legs-list :journey="journey"></legs-list>
    </div>
  </div>
</template>

<script>
import mixin from '../../mixins/mixin';
import config from "../../config";
import moment from 'moment';
import LegsList from '../LegsList.vue';
import objectName from './cards/ObjectName.vue';

export default {
  name: "JourneyPanel",
  props: ['journey'],
  mixins: [mixin],
  components: { LegsList, objectName },
  data() {
    return {
      vehicles: config.vehicles
    }
  },
  created() {
  },
  methods: {
    formatDate(date) {
      return moment(date).format('m/d/yyyy');
    },
    formatStart(leg) {
      return moment(leg.startsAt).format('HH:mm');
    },
    hasBooking(leg) {
      return leg.detail?.ridehailing?.ridehailingBooking?.bookingName;
    },
    getBookingLink(leg) {
      if(leg.detail?.ridehailing?.ridehailingBooking?.bookingName) {
        const date = moment(leg.startsAt)
        return `/ridehailing/${date.format('YYYY-MM-DD')}/${date.format('YYYY-MM-DD')}/book?name=${leg.detail.ridehailing.ridehailingBooking.bookingName}`;
      }
      return '#';
    },
    getLegType(leg) {
      let vehicle = Object.keys(leg.detail)[0];
      if (vehicle == "transit") {
        return "Transit";
      }
      if (vehicle == "ridehailing") {
        return "taxi";
      }
      if (vehicle == "privateMotorVehicle") {
        return "motorcycle";
      }
      return config.vehicles[vehicle].name;
    },
  },
}
</script>
<style scoped>
.detail-view {
  height: 562px;
  overflow-y: scroll;
} 
.leg {
  border-radius: 4px;
  border: 1px solid #f0f0f0;
  padding: 6px 10px;
  margin-bottom: 10px;
  box-shadow: rgba(0, 0, 0, .05) 0 0px 10px;
}
</style>
<style>
.detail-view .list-group-item {
  padding: 6px 10px;
}
</style>