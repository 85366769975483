<template>
  <div class="row">
    <div class="col">
      <sharedvehicle-session-card :session="item" @cancel-booking="cancelBooking()" />
    </div>
    <div class="col">
      <company-charges v-if="showChargesBreakdown" :data="item.charge" />
      <stripe-card :payment_intents="item.stripe_payment_intents" />
      <div id="map"></div>
    </div>
    <div class="col">
      <user-records :user="item.user" />
      <company-card :company="item.company" />
    </div>
  </div>
</template>

<script>
import mixin from '../../mixins/mixin';
import CompanyCharges from '../CompanyCharges.vue';
import UserRecords from './cards/UserRecords.vue';
import CompanyCard from './cards/CompanyCard.vue';
import SharedvehicleSessionCard from './cards/SharedvehicleSessionCard.vue';
import StripeCard from './cards/StripeCard.vue';

export default {
  components: { CompanyCharges, UserRecords, CompanyCard, StripeCard, SharedvehicleSessionCard },
  name: "MicromobilityPanel",
  props: ['item'],
  mixins: [mixin],
  methods: {
    cancelBooking() {
      this.$emit("cancel-booking");
    },
    originParkingSpaceMapLabel() {
      let label = "<b>Origin: "+ this.item.origin_parking_space.displayName + "</b>";
      label += "<br>" + this.item.origin_parking_space.entrances[0].unstructuredAddress;
      return label;
    },
    destinationParkingSpaceMapLabel() {
      let label = "<b>Origin: "+ this.item.destination_parking_space.displayName + "</b>";
      label += "<br>" + this.item.destination_parking_space.entrances[0].unstructuredAddress;
      return label;
    }
  },
  mounted() {
    this.map = L.map("map");
    
    L.tileLayer(
      "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}",
      {
        tileSize: 512,
        zoomOffset: -1,
        id: "mapbox/streets-v11",
        accessToken:
          "pk.eyJ1IjoidmljdG9ybGYiLCJhIjoiY2pvOGUxZHB0MDk0NDN0bjFwazJtZWJvayJ9.fCoUbelPKU2m6t88oL1M7w",
      }
    ).addTo(this.map);

    var markers = [];

    //
    // Add markers if not already present. FIXME: check does not work
    //

    // Origin
    if (this.item.originLatlng) {
      const position = [this.item.originLatlng.latitude, this.item.originLatlng.longitude];
      if (!markers.includes(position)) {
        markers.push(position);
        let marker = L.marker(position).addTo(this.map);
        marker.bindTooltip("Origin", {permanent: true}).openTooltip();
      }
    }

    // Current position
    if (this.item.vehicles && this.item.vehicles[0].currentPosition) {
      const position = [this.item.vehicles[0].currentPosition.latitude,
                        this.item.vehicles[0].currentPosition.longitude];
      if (!markers.includes(position)) {
        markers.push(position);
        let marker = L.marker(position).addTo(this.map);
        marker.bindTooltip("Current position", {permanent: true}).openTooltip();
      }
    }

    // User reported position
    if (this.item.userReportedLatlng) {
      const position = [this.item.userReportedLatlng.latitude, this.item.userReportedLatlng.longitude];
      if (!markers.includes(position)) {
        markers.push(position);
        let marker = L.marker(position).addTo(this.map);
        marker.bindTooltip("Last user reported position", {permanent: true}).openTooltip();
      }
    }

    // Session logs
    for (let log of this.item.sessionLogs) {
      if (log.latlng) {
        const position = [log.latlng.latitude, log.latlng.longitude];
        if (!markers.includes(position)) {
          // console.log(markers, position);
          markers.push(position);
          let marker = L.marker(position).addTo(this.map);
        } 
      }
    }

    // Origin parking space
    if (this.item.origin_parking_space?.entrances[0].position) {
      const position = [this.item.origin_parking_space.entrances[0].position.latitude,
                        this.item.origin_parking_space.entrances[0].position.longitude];
      markers.push(position);
      let marker = L.marker(position).addTo(this.map);
      marker.bindTooltip(this.originParkingSpaceMapLabel(), {permanent: true}).openTooltip();
    }

    // Destination parking space
    /*if (this.item.destination_parking_space?.entrances[0].position) {
      const position = [this.item.destination_parking_space.entrances[0].position.latitude,
                        this.item.destination_parking_space.entrances[0].position.longitude];
      markers.push(position);
      let marker = L.marker(position).addTo(this.map);
      marker.bindTooltip("Destination", {permanent: true}).openTooltip();
    }*/

    if (markers) {
      this.map.fitBounds(markers, {padding: [70, 70]});
    }
  },
  beforeDestroy() {
    if (this.map) {
      this.map.remove();
    }
  }
}
</script>

<style scoped>
#map {
  width: 100%;
  height: 100%;
  max-height: 650px;
}
</style>