import moment from "moment";

export default {
    data() {
        return {
            sortingFunctions: {
                createTime: (dir) => {
                    let inverter = dir == 'asc' ? 1 : -1; 
                    return (rowX, rowY) => {            
                        return inverter * ( moment(rowX.createTime).isBefore(moment(rowY.createTime)) ? -1 : 1);
                    }
                },
                fullName: (dir) => {
                    let inverter = dir == 'asc' ? 1 : -1; 
                    return (rowX, rowY) => {
                        if (!rowX.user?.names?.names.trim()) {
                            return -1 * inverter;
                        }
                        const stringX = rowX.user?.names?.names.trim().toLowerCase();
                        const stringY = rowY.user?.names?.names.trim().toLowerCase();
                        
                        return inverter * stringX.localeCompare(stringY);
                    }
                },
                client: (dir) => {
                    let inverter = dir == 'asc' ? 1 : -1; 
                    return (rowX, rowY) => {
                        if (!rowX.name) {
                            return -1 * inverter;
                        }
                        const stringX = rowX.name.split('/')[1].toLowerCase();
                        const stringY = rowY.name.split('/')[1].toLowerCase();
                        
                        return inverter * stringX.localeCompare(stringY);
                    }
                },
                email: (dir) => {
                    let inverter = dir == 'asc' ? 1 : -1; 
                    return (rowX, rowY) => {
                        if (!rowX.user?.email?.email) {
                            return -1 * inverter;
                        }
                        const stringX = rowX.user?.email?.email.trim().toLowerCase();
                        const stringY = rowY.user?.email?.email.trim().toLowerCase();
                        
                        return inverter * stringX.localeCompare(stringY);
                    }
                },
                phoneNumber: (dir) => {
                    let inverter = dir == 'asc' ? 1 : -1; 
                    return (rowX, rowY) => {
                        if (!rowX.user?.phoneNumber?.phoneNumber) {
                            return -1 * inverter;
                        }
                        const stringX = rowX.user?.phoneNumber?.phoneNumber.toString().trim().toLowerCase();
                        const stringY = rowY.user?.phoneNumber?.phoneNumber.toString().trim().toLowerCase();
                        
                        return inverter * stringX.localeCompare(stringY);
                    }
                },
                company: (dir) => {
                    let inverter = dir == 'asc' ? 1 : -1; 
                    return (rowX, rowY) => {
                        if (!rowX.company?.displayName) {
                            return -1;
                        }
                        const stringX = rowX.company?.displayName.trim().toLowerCase();
                        const stringY = rowY.company?.displayName.trim().toLowerCase();
                        
                        return inverter * stringX.localeCompare(stringY);
                    }
                },
                vehicle: (dir) => {
                    let inverter = dir == 'asc' ? 1 : -1; 
                    return (rowX, rowY) => {
                        if(rowX.vehicles.length == 0) {
                            return -1 * inverter;
                        }
                        if(rowY.vehicles.length == 0) {
                            return 1 *inverter;
                        }
                        return inverter* (this.vehicleName(rowX.vehicles[0].info.type) < this.vehicleName(rowY.vehicles[0].info.type) ? -1 : 1);
                    }
                },
                status: (dir) => {
                    let inverter = dir == 'asc' ? 1 : -1; 
                    return (rowX, rowY) => {
                        const stringX = rowX.status.trim().toLowerCase();
                        const stringY = rowY.status.trim().toLowerCase();
                        
                        return inverter * stringX.localeCompare(stringY);
                    }
                },
                price: (dir) => {
                    let inverter = dir == 'asc' ? 1 : -1; 
                    return (rowX, rowY) => {

                        let fieldX = rowX.cost;
                        if(fieldX === undefined) {
                            fieldX = rowX.price;
                        }
                        if(fieldX === undefined) {
                            fieldX = rowX.bid?.fare?.range?.min;
                        }
                        let fieldY = rowY.cost;
                        if(fieldY === undefined) {
                            fieldY = rowY.price;
                        }
                        if(fieldY === undefined) {
                            fieldY = rowY.bid?.fare?.range?.min;
                        }

                        if (!fieldX) {
                            return -1 * inverter;
                        }
                        if (!fieldY) {
                            return 1 * inverter;
                        }
                        
                        let priceX = 0;
                        let priceY = 0;

                        if ("units" in fieldX) {
                            priceX += parseInt(fieldX.units);
                        }
                        if ("nanos" in fieldX) {
                            priceX += parseInt(fieldX.nanos) / 1e9;
                        }
                        if ("units" in fieldY) {
                            priceY += parseInt(fieldY.units);
                        }
                        if ("nanos" in fieldY) {
                            priceY += parseInt(fieldY.nanos) / 1e9;
                        }

                        return inverter * (priceX < priceY ? -1 : 1);
                    }
                },
                startTime: (dir) => {
                    let inverter = dir == 'asc' ? 1 : -1; 
                    return (rowX, rowY) => {            
                        return inverter * (moment(rowX.startTime).isBefore(moment(rowY.startTime)) ? -1 : 1);
                    }
                },
                duration: (dir) => {
                    let inverter = dir == 'asc' ? 1 : -1; 
                    return (rowX, rowY) => {            
                        rowX.duration = null;
                        rowY.duration = null
                        const data = {
                            rowX, rowY
                        }

                        Object.keys(data).forEach(label => {
                            if(data[label].startTime && data[label].endTime) {
                                data[label].duration = 
                                    moment.duration(
                                        moment(data[label].endTime)
                                        .diff(moment(data[label].startTime))
                                    );
                            }
                        });
                        if(rowX.duration == null) {
                            return -1 * inverter;
                        }
                        return inverter * (data.rowX.duration < data.rowY.duration ? -1 : 1);
                    }
                }
            },
        }
    },
};