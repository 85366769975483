<template>
  <div>
    <loading
      v-model="isLoading"
      :active="isLoading"
      :is-full-page="true"
      color="#fff"
      background-color="#000"
    />

    <section class="content">
      <div class="container-fluid pt-2">
        <div class="card">
          <div class="card-header bg-info align-middle ">
            <h1 class="card-title">JOURNEYS</h1>
            <div class="card-tools">
              <div class="d-flex justify-content-end align-items-center">
              <date-range
                @changed="filterByDateRange"
                :start-date="dateRange.start"
                :end-date="dateRange.end"
                :type="dateRange.type"
              />
              <i></i>
              <input
                type="search"
                @keyup="applySearch"
                class="form-control"
                v-model="searchText"
                placeholder="Search..."
              />
              <i></i>
              <download-json :data="rowsFiltered" filename="journeys.json" />
              <download-csv @click="saveCSV()" />
            </div>
            </div>
          </div>
          <!--div class="d-flex ml-4 mt-4">
            <div class="mr-3">From: <span style="font-weight: bold">{{ minDate }}</span> to <span style="font-weight: bold">{{ maxDate }}</span></div>    
            <div class="mr-3" v-if="priceString">
              price: <span style="font-weight: bold">{{ priceString }}</span>
            </div>
            <div v-if="durationString">
              duration: <span style="font-weight: bold">{{ durationString }}</span>
            </div>
          </div-->
          <div class="card-body">
            <fast-table :items="rowsFiltered" :columns="tableCols" v-slot="props" @sort="onSort" :defaultSortField="defaultSortField" styleClass="journey">
              <div class="dynamic-item" @click="onRowClick(props.item)">
                <div class="td" :style="`width: ${getColumnWidth('createTime')}`">{{ customizeDate(props.item.createTime) }}</div>
                <div class="td" v-if="hasCustomerColumn" :style="`width: ${getColumnWidth('client')}`">
                  {{ getCustomerName(props.item) }}
                </div>
                <div class="td" :style="`width: ${getColumnWidth('fullName')}`">
                  <span class="mr-1"
                    :class="notValidatedClass(props.item.user?.names)"
                  >{{ props.item.user?.names?.names }}</span>
                  <span :class="notValidatedClass(props.item.user?.surnames)">{{
                    props.item.user?.surnames?.surnames
                  }}</span>
                </div>
                <div class="td" :style="`width: ${getColumnWidth('email')}`">
                  <span :class="notValidatedClass(props.item.user.email)">{{
                    props.item.user.email?.email
                  }}</span>
                </div>
                <div class="td" :style="`width: ${getColumnWidth('phoneNumber')}`">
                  <span
                    :class="notValidatedClass(props.item.user?.phoneNumber)"
                    >{{ props.item.user?.phoneNumber?.phoneNumber }}</span
                  >
                </div>
                <div class="td" :style="`width: ${getColumnWidth('mobility')}`">
                  <img
                    v-if="props.item?.company?.visualAssets?.squareLogo?.urlSvg"
                    :src="props.item.company.visualAssets.squareLogo.urlSvg"
                  />
                  &nbsp;
                  <span>{{ props.item.company?.displayName }}</span>
                </div>
                <div class="td" :style="`width: ${getColumnWidth('tripSummary')}`">
                  <span v-if="props.item?.tripSummary">
                    {{ props.item.tripSummary }}
                  </span>
                </div>
                <div class="td" :style="`width: ${getColumnWidth('status')}`">
                  <span :class="getStatusClass(props.item.state)">{{
                    getStatusLabel(props.item.state)
                  }}</span>
                </div>
                <div class="td" :style="`width: ${getColumnWidth('startTime')}`">
                  {{ customizeDate(props.item.startTime) }}
                </div>
              </div>
            </fast-table>
          </div>
        </div>
      </div>
    </section>

    <modal-popup v-if="showModal" size="fullscreen" title="Journey" @close="closeModal()">
      <template #body>
        <journey-panel :journey="selected" :showModalPanel="showModalPanel"></journey-panel>
      </template>
    </modal-popup>
  </div>
</template>

<script>
import api from "../services/api";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import moment from "moment";
import mixin from "../mixins/mixin";
import downloadMixin from "../mixins/download-mixin";
import sortMixin from "../mixins/sort-mixin";
import DateRange from "../components/DateRange.vue";
import config from "../config";
import DownloadJson from '../components/DownloadJson.vue';
import DownloadCsv from '../components/DownloadCsv.vue';
import ModalPopup from '../components/ModalPopup.vue';
import JourneyPanel from '../components/panels/JourneyPanel.vue';

import FastTable from '../components/FastTable.vue';

const L = window.L;

export default {
  name: "JourneyList",
  components: {
    Loading,
    DateRange,
    DownloadJson,
    DownloadCsv,
    ModalPopup,
    JourneyPanel,
    FastTable
  },
  mixins: [mixin, sortMixin, downloadMixin],
  data() {
    return {
      isLoading: false,
      showModalPanel: false,
      rows: [],
      rowsFiltered: [],
      journeys: [],
      status: {
        ACTIVE: "Active",
        COMPLETED: "Completed",
        BOOKED: "Booked",
        CANCELLED: "Cancelled",
        CONFIRMED: "Confirmed",
        DRAFTED: "Drafted",
        CREATED: "Created",
        FINISHED: "Finished",
      },
      selected: null,
      searchText: "",
      currentLeg: null,
      dateType: 'book',
      defaultSortField: 'createTime',
      tableCols: [
        { text: "Booked date", direction: "desc", field: "createTime", sortable: true, width: 9 },
        { text: "Full name", field: "fullName", sortable: true, width: 13 },
        { text: "Email", field: "email", sortable: true, width: 20 },
        { text: "Phone number", field: "phoneNumber", sortable: true, width: 9 },
        { text: "Mobility mode", field: "mobility", sortable: false, width: 8 },
        { text: "Trip summary", field: "tripSummary", sortable: false, width: 26 },
        { text: "Status", field: "status", sortable: true, width: 5 },
        { text: "Date & Time", field: "startTime", sortable: true, width: 10 }
      ],
      legs: [],
      minDate: "",
      maxDate: "",
      priceString: "",
      durationString: "",
    };
  },
  created() {
    this.dateRange = { ...this.$store.state.dateRange};
    this.currentPath = this.$route.fullPath;
    this.initFilters();
    this.manageCustomerColumn();
  },
  methods: {
    createTableSummary() {
      this.minDate = "";
      this.maxDate = "";
      let arrayBounds = [];
      this.rowsFiltered.forEach(row => {
        if(row.inbound) {
          arrayBounds.push(row.inbound);
        }
        if(row.outbound) {
          arrayBounds.push(row.outbound);
        }
        if(row.inboundRoutes) {
          row.inboundRoutes.forEach(leg => {
            if(leg.startTime || leg.endTime) {
              arrayBounds.push(leg);
            }
          })
        }
        if(row.outboundRoutes) {
          row.outboundRoutes.forEach(leg => {
            if(leg.startTime || leg.endTime) {
              arrayBounds.push(leg);
            }
          })
        }          
      });
      let prices = {};
      arrayBounds.forEach(bound => {
        if(bound.legs && bound.legs.length > 0){
          bound.legs.forEach(leg => {
            if(leg.detail && leg.detail?.transit?.alternatives) {
              leg.detail?.transit?.alternatives.forEach(alt => {
                if(alt?.fares) {
                  alt.fares.forEach(fare => {
                    if(fare?.cost?.min?.currencyCode) {
                      let currency = fare.cost?.min?.currencyCode;
                      if(! prices[currency]) {
                        prices[currency] = {
                          min: 0,
                          max: 0
                        };
                      }
                      prices[currency].min += parseInt(fare.cost?.min?.units) + (parseInt(fare.cost?.min?.nanos) /1e9);
                      prices[currency].max += parseInt(fare.cost?.max?.units) + (parseInt(fare.cost?.max?.nanos) /1e9);
                    }
                  })

                }
              })
            }
          })
        }
        if(! this.minDate) {
          this.minDate = moment(bound.startTime).format('YYYY-MM-DD hh:mm');
        }
        if(! this.maxDate) {
          this.maxDate = moment(bound.endTime).format('YYYY-MM-DD hh:mm');
        }
        if(moment(bound.startTime).isBefore(moment(this.minDate))) {
          this.minDate = moment(bound.startTime).format('YYYY-MM-DD hh:mm');
        }
        if(moment(bound.endTime).isAfter(moment(this.maxDate))) {
          this.maxDate = moment(bound.endTime).format('YYYY-MM-DD hh:mm');
        }

      }); 
      let rangePrices = "";
      for (let price in prices) {
        const currency = this.getCurrencyLabel(price);
        rangePrices += ", " + prices[price].min.toFixed(2) + currency + "-" + prices[price].max.toFixed(2) + currency;
      }
      this.durationString = this.differenceBetweenDate(this.minDate, this.maxDate);
      this.priceString = rangePrices.slice(2);
    },
    getLegType(leg) {
      let vehicle = Object.keys(leg.detail)[0];
      if (vehicle == "transit") {
        return "Transit";
      }
      if (vehicle == "ridehailing") {
        return "taxi";
      }
      if (vehicle == "privateMotorVehicle") {
        return "motorcycle";
      }
      if(config.vehicles[vehicle] && config.vehicles[vehicle].name) {
        return config.vehicles[vehicle].name;
      }
      return vehicle;
    },
    showModalFromLink() {
      if(this.$route.query?.name) {
        const row = this.rowsFiltered.find(item => {
          if(item.name == this.$route.query.name) {
            return item;
          }
        })
        this.onRowClick(row);
      }
    },
    async initFilters() {
      if(! this.$route.params.start) {
        this.$router.push(`/journeys/${moment(this.dateRange.start).format("YYYY-MM-DD")}/${moment(this.dateRange.end).format("YYYY-MM-DD")}/${this.dateRange.type}`)
      } else {
        await this.loadData();
        this.showModalFromLink();
      }
    },
    onSort(params) {
      if(params.direction == null) {
        this.rowsFiltered = [ ...this.rows ];
        return;
      }
      if(this.sortingFunctions[params.field]) {
        this.rowsFiltered = this.rowsFiltered.sort(this.sortingFunctions[params.field](params.direction));
      }
    },
    duration(start, duration) {
      return moment(start).add(duration, "s");
    },
    async filterByDateRange(payload) {
      this.dateRange = payload.range;
      this.dateType = payload.type;

      if(this.dateType == "book") {
        this.defaultSortField = "createTime";
      } else {
        this.defaultSortField = "startTime";
      }
      this.$store.commit('setDateRange', { start: this.dateRange.start, end: this.dateRange.end, type: this.dateType });
      this.$router.push(`/journeys/${moment(this.dateRange.start).format("YYYY-MM-DD")}/${moment(this.dateRange.end).format("YYYY-MM-DD")}/${this.dateType}`);
      this.loadData();
    },
    applySearch() {
      clearInterval(this.searchTimeout);
        this.searchTimeout = setTimeout(() => {
        this.rowsFiltered = [...this.rows];
        if(this.searchText.length > 0) {
          this.rowsFiltered = this.rowsFiltered.filter((item) => {
            return this.stringInObject(this.searchText, item);
          });
          this.createTableSummary();
        }
      }, 250);
    },
    async loadData() {
      this.isLoading = true;
      try {
        const start = this.dateRange
          ? moment(this.dateRange.start).startOf('day').toISOString(true)
          : "";
        const end = this.dateRange
          ? moment(this.dateRange.end).endOf('day').toISOString(true)
          : "";
        
        
        const params = new URLSearchParams({
          start, end, date: this.dateType 
        });
        const data = await api().get(`/api/journey?${params.toString()}`);
        if(data?.data?.data) {
          this.journeys = data.data.data;
          this.rows = this.journeys;
          this.rows.forEach(item => {
            item.extra = {
              nameSurname: `${item.user?.names?.names.trim()} ${item.user?.surnames?.surnames.trim()}`.trim()
            };
            let routesList = [];
            if(item.inbound) {
              routesList.push(item.inbound);
            }
            if(item.outbound) {
              routesList.push(item.outbound);
            }
            if(item.inboundRoutes) {
              item.inboundRoutes.forEach(leg => {
                if(leg.duration) {
                  routesList.push(leg);
                }
              })
            }
            if(item.outboundRoutes) {
              item.outboundRoutes.forEach(leg => {
                if(leg.duration) {
                  routesList.push(leg);
                }
              })
            }
            let data = [];
            if(routesList[0]) {
              data.push(moment(routesList[0].startTime).format("DD/MM/YYYY") + " " + routesList[0].userSpecifiedOrigin);
            }
            routesList.forEach(item => {
              if(item.legs) {
                item.legs.forEach(leg => {
                  if(leg.detail && leg.startsAt) {
                    data.push(this.getLegType(leg) + " " + moment(leg.startsAt).format("HH:mm"));
                  }
                });
              }    
            });
            if(routesList[routesList.length - 1]) {
              data.push(moment(routesList[routesList.length - 1].endTime).format("DD/MM/YYYY") + " " + routesList[routesList.length - 1].userSpecifiedDestination);  
            }        
            item.tripSummary = data.join("\u2192 ");
            return item;
          })
          this.rowsFiltered = [ ...this.rows];
          
          this.createTableSummary();
          
          this.onSort({field: "createTime", direction: "desc"});
        }
      } catch (e) {
        if(e.response?.status == 401 || e.response?.status == 403) {
          return;
        }
        console.log(e)
        this.$notify({
          type: "error",
          title: "Error",
          text: e.response?.data?.message || e.message
        });
      }
      this.isLoading = false;
    },
    canCancel(data) {
      return !["COMPLETED", "CANCELED_BY_PASSENGER", "DELETED"].includes(
        data.state
      );
    },
    
    getLegs(journey) {
      let legs = [];
      if(journey.inbound?.legs) {
        legs = [...legs, ...journey.inbound.legs];
      }
      if(journey.outbound?.legs) {
        legs = [...journey.outbound.legs];
      }      
      if(journey.inboundRoutes) {
        journey.inboundRoutes.forEach(route => {
          if(route.legs) {
            legs = [...legs, ...route.legs];
          }
        });
      }
      if(journey.outboundRoutes) {
        journey.outboundRoutes.forEach(route => {
          if(route.legs) {
            legs = [...legs, ...route.legs];
          }
        });
      }
      legs = legs.filter(leg => {
        return leg.origin?.position && leg.destination?.position;
      })
      return legs;
    },
    onRowClick(params) {
      this.$router.push({ path: this.$route.path, query: { name: params.name } });
      this.selected = params;
      this.showModal = true;
      const coordinates = [];
      this.legs = this.getLegs(this.selected);

      this.legs.forEach(leg => {
        if(leg.origin?.position && leg.destination?.position) {
          coordinates.push([ leg.origin.position.latitude, leg.origin.position.longitude ],
          [ leg.destination.position.latitude, leg.destination.position.longitude ]);
        }        
      })

      this.$nextTick(() => {
        const map = L.map("map", {
          zoom: 12,
        });
        L.tileLayer(
          "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}",
          {
            maxZoom: 22,
            id: "mapbox/streets-v11",
            accessToken: "pk.eyJ1IjoidmljdG9ybGYiLCJhIjoiY2pvOGUxZHB0MDk0NDN0bjFwazJtZWJvayJ9.fCoUbelPKU2m6t88oL1M7w"
          }
        ).addTo(map);
        if(coordinates.length) {
          map.fitBounds(coordinates);
        }
        const lastIndex = this.legs.length - 1;
        /**
         * add start icon
         */
        if (this.legs.length > 0) {
          L.marker(
            [
              this.legs[0].origin.position.latitude,
              this.legs[0].origin.position.longitude,
            ],
            {
              icon: this.getStartIcon(),
            }
          ).addTo(map);
        }

        /**
         * add end icon only if the trip is completed
         */
        if(this.selected.state == "FINISHED" && this.legs.length > 0) {
          L.marker(
            [
              legs[lastIndex].destination.position.latitude,
              legs[lastIndex].destination.position.longitude,
            ],
            {
              icon: this.getEndIcon(),
            }
          ).addTo(map);
        }

        this.legs.forEach((leg, index) => {
          let vehicle = Object.keys(leg.detail)[0];
          let color = "#999";
          let dashArray = "";

          if (vehicle == "transit") {
            dashArray = "8 8";
          }
          if (vehicle == "ridehailing") {
            vehicle = "taxi";
          }
          if (vehicle == "privateMotorVehicle") {
            vehicle = "motorcycle";
          }

          let latlngs = [];
          if(leg.trajectory?.points) {
            latlngs = leg.trajectory.points.map((item) => {
              return [item.latitude, item.longitude];
            });
          }          

          const isLastLegAndFinished = (lastIndex == index && this.selected.state == "FINISHED");
          if (vehicle != "transit" && !isLastLegAndFinished) {
            const icon = this.getLegIcon(vehicle);
            L.marker(
              [
                leg.destination.position.latitude,
                leg.destination.position.longitude,
              ],
              {
                icon,
              }
            )
              /*.on("click", () => {
                this.showModalPanel = true;
                this.currentLeg = leg;
                this.$nextTick(() => {
                  map.invalidateSize();
                  map.fitBounds(latlngs);
                });
              })*/
              .addTo(map);
          }

          if (config.vehicles[vehicle]) {
            color = config.vehicles[vehicle]?.color;
          }

          const polyline = L.polyline(latlngs, {
            color,
            smoothFactor: 2,
            weight: 4,
            dashArray,
          });
          if (vehicle != "transit") {
            polyline
              .on("click", () => {
                this.showModalPanel = true;
                this.currentLeg = leg;
                this.$nextTick(() => {
                  map.invalidateSize();
                  map.fitBounds(latlngs);
                });
              })
              .on("mouseover", () => {
                polyline.setStyle({
                  weight: 7,
                });
              })
              .on("mouseout", () => {
                polyline.setStyle({
                  weight: 4,
                });
              })
              .addTo(map);
          }
        });
      });
    },
    getLegIcon(vehicle) {
      return L.divIcon({
        html: `
                <div class='iomob-marker'>
                  <span class='icon-marker'>
                    <i style="color: ${config.vehicles[vehicle]?.color}" class="fa-solid fa-location-pin"></i>
                  </span>
                  <span class='icon'>
                    <i class="${config.vehicles[vehicle]?.icon} fa-2x vehicle"></i>
                  </span>
                </div>
                `,
        iconSize: [36, 44],
        iconAnchor: [18, 44],
        className: "marker-icon",
      });
    },
    getCustomIcon(markerClasses, iconClasses) {
      return L.divIcon({
        html: `
                <div class='iomob-marker'>
                  <span class='icon-marker ${markerClasses}'>
                    <i class="fa-solid ${iconClasses}"></i>
                  </span>
                </div>
                `,
        iconSize: [36, 44],
        iconAnchor: [18, 44],
        className: "marker-icon",
      });
    },
    getStartIcon() {
      return this.getCustomIcon("icon-marker-start", "fa-location-dot");
    },
    getEndIcon() {
      return L.divIcon({
        html: `
                <div class='iomob-marker end-icon'>
                  <span class='icon-marker icon-marker-end'>
                    <i class="fa-solid fa-location-pin"></i>
                  </span>
                  <span class='icon'>
                    <i class="fa-solid fa-bullseye fa-2x end"></i>
                  </span>
                </div>
                `,
        iconSize: [36, 44],
        iconAnchor: [18, 44],
        className: "marker-icon",
      });
    },
    getStatusLabel(status) {
      if (this.status[status]) {
        return this.status[status];
      }
      return status;
    },
    filters() {
      this.rowsFiltered = [...this.rows];
      if (this.period) {
        let dateBefore = moment();
        if (this.period == "w") {
          dateBefore = dateBefore.subtract(7, "days");
        }
        if (this.period == "ww") {
          dateBefore = dateBefore.subtract(14, "days");
        }
        this.rowsFiltered = this.rows.filter((item) =>
          moment(item.endTime).isAfter(dateBefore)
        );
      }
    },
    saveCSV() {
      const data = [
        [
          'Booking time',
          'Full name',
          'Email',
          'Phone number',
          'Company',
          'Addresses',
          'Status',
          'Date & Time'
        ],
        ...this.rowsFiltered.map(item => {
          return [
            this.customizeDate(item.createTime),
            `${item.user?.names?.names} ${item.user?.surnames?.surnames}`,
            item.user.email?.email,
            item.user?.phoneNumber?.phoneNumber,
            item.company?.displayName || "",
            item.outbound?.userSpecifiedOrigin || "",
            this.getStatusLabel(item.state),
            this.customizeDate(item.startTime),
          ]
        })
      ];
      this.downloadBlob("journeys.csv", this.arrayToCsv(data),  "text/csv");
    }
  },
};
</script>

<style scoped lang="scss">
h4 {
  font-size: 1.2rem;
  font-weight: 600;
  color: #666;
}
.vehicles-legend {
  > div {
    border-bottom: 1px solid #e0e0e0;
    .icon {
      padding: 4px 6px;
      border-radius: 4px;
      display: inline-block;
    }
  }
}
</style>
<style lang="scss">
#map {
  width: 100%;
  height: 600px;
}
.iomob-marker {
  position: relative;
  width: 36px;
  height: 44px;
  display: block;

  &.end-icon {
      .icon {
        .end {
          color:#fff;
          font-size: 20px;
        }
        top: 13px;
      }
    }

  > span {
    position: absolute;
    z-index: 1;
    font-size: 20px;
    text-align: center;
    width: 44px;

    &.icon-marker-start {
      color: #003F6B;
      font-size: 44px;
    }
    &.icon-marker-end {
      color: #003F6B;
      font-size: 44px;
    }

    .vehicle {
      font-size: 20px;
    }

    [data-icon="location-pin"] {
      z-index: 0;
      font-size: 44px;
    }
  }
}

</style>
